import { UpdateType } from '../../@types/api';
import AutomaticIcon from './AutomaticIcon';
import ManualIcon from './ManualIcon';
import SemiIcon from './SemiIcon';

type ResolveModelIconProps = {
  updateType: UpdateType;
};

const ResolveModelIcon = (props: ResolveModelIconProps) => {
  if (props.updateType === 'semi_automatic') {
    return <SemiIcon />;
  } else if (props.updateType === 'automatic') {
    return <AutomaticIcon />;
  }
  return <ManualIcon />;
};

export default ResolveModelIcon;
