import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType, StyleObj } from '../../../@types';
import { MarketType, OutcomeType } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import useMutateData from '../../../hooks/useMutateData';
import { OutcomeTypeFormData, outcomeTypeFormSchema } from '../../../schema';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormNumberInput from '../../molecules/FormNumberInput';
import FormAutocomplete from '../FormAutocomplete';

const DEFAULT_OUTCOME_TYPE_FORM_DATA = {
  marketTypeId: '',
  name: '',
  shortName: null,
  description: null,
  position: null,
  isActive: true,
};

const styles: StyleObj = {
  position: {
    width: 80,
  },
};

type Props = DialogType;

const OutcomeTypeForm = ({ closeModal }: Props) => {
  const { item } = useModal<OutcomeType>();
  const { state } = useLocation();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<OutcomeTypeFormData>({
    defaultValues: DEFAULT_OUTCOME_TYPE_FORM_DATA,
    resolver: zodResolver(outcomeTypeFormSchema),
  });

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        marketTypeId: item.marketType.id,
      });
    } else {
      reset({
        ...DEFAULT_OUTCOME_TYPE_FORM_DATA,
        ...state?.defaultValues,
      });
    }
  }, [state?.defaultValues, item, reset]);

  const { createData, updateData } = useMutateData('outcome-types', [QUERY_KEYS.outcomeTypes]);

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_OUTCOME_TYPE_FORM_DATA);
  };

  const onFormSubmit = (newOutcome: OutcomeTypeFormData) => {
    if (item) {
      updateData(item.id, newOutcome, () => {
        handleClose();
      });
    } else {
      createData(newOutcome, () => {
        handleClose();
      });
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={item ? 'Edit outcome' : 'Add new outcome'}
      onClose={handleClose}
      isEdit={!!item}
    >
      <FormAutocomplete<MarketType, OutcomeTypeFormData>
        name="marketTypeId"
        control={control}
        label="Market"
        url="market-types"
        queryKey={[QUERY_KEYS.marketTypes]}
        queryParams={{
          isActive: true,
          marketTypeIds: [item?.marketType.id],
        }}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option ? `${option?.name}, ${option?.eventPartName || ''}` : '';
        }}
        disabled={!!item}
      />
      <TextField error={!!errors.name} helperText={errors.name?.message} label="Name" {...register('name')} required />
      <TextField
        error={!!errors.shortName}
        helperText={errors.shortName?.message}
        label="Short name"
        {...register('shortName')}
      />
      <TextField
        error={!!errors.description}
        helperText={errors.description?.message}
        label="Description"
        multiline
        {...register('description')}
      />
      <FormFieldStack label="Position">
        <FormNumberInput name="position" control={control} error={errors.position} sx={styles.position} />
      </FormFieldStack>
      <FormFieldStack label="Status">
        <Controller name="isActive" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
    </FormModalLayout>
  );
};

export default OutcomeTypeForm;
