import type { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import type { OutcomeType } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { createDescriptionColumn } from '../../helpers/columns';
import { defaultColumnsOutcomes } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import Switch from '../atoms/Switch';
import EditTableItem from '../organisms/EditTableItem';
import TableTemplate from '../templates/TableTemplate';
import { getMarketTypeName } from '../../helpers';

const OutcomeTypesPage = () => {
  const {
    data: outcomesData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<OutcomeType>('outcome-types');
  const { deleteData, updateData } = useMutateData('outcome-types', [QUERY_KEYS.outcomeTypes]);
  const { handleSort } = useSort(changeQuery);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const columns: GridColDef[] = [
    createColumn('id', 'ID', { sortable: false }),
    createColumn('sportName', 'Sport', {
      valueGetter: (params: GridValueGetterParams<OutcomeType>) => params.row.sport.name,
    }),
    createColumn('marketTypeName', 'Market', {
      valueGetter: (params: GridValueGetterParams<OutcomeType>) => getMarketTypeName(params.row.marketType),
    }),
    createColumn('name', 'Outcome Name'),
    createColumn('shortName', 'Outcome Short Name'),
    createColumn('position', 'Position'),
    createDescriptionColumn(),
    createColumn('isActive', 'Status', {
      renderCell: (params) => {
        return (
          <Switch
            value={params.value}
            onChange={(e) => {
              updateData(params.row.id, { isActive: e.target.checked });
            }}
            name="status"
          />
        );
      },
    }),
    createColumn('edit', 'Edit', {
      sortable: false,
      renderCell: (params) => (
        <EditTableItem<OutcomeType>
          handleDelete={() => {
            deleteData(params.row.id);
          }}
          item={params.row}
        />
      ),
    }),
  ];

  return (
    <TableTemplate
      rows={outcomesData?.items || []}
      columns={columns}
      loading={isFetching || isLoading}
      rowCount={outcomesData?.count || 0}
      createItemBtnLabel="Add new outcome"
      defaultVisibleColumns={defaultColumnsOutcomes}
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
      formType="outcomes"
      showExport
    />
  );
};

export default OutcomeTypesPage;
