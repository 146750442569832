import { Typography, TypographyProps } from '@mui/material';

import { StyleObj } from '../../@types';

const styles: StyleObj = {
  oldValue: {
    textDecoration: 'line-through',
    opacity: 0.75,
    pr: 0.25,
  },
};

type TicketDetailsInfoItemProps = {
  label?: string;
  oldValue?: string | number | null;
  newValue?: string | number | null;
  isStatus?: boolean;
  textVariant?: TypographyProps['variant'];
};

const TicketDetailsInfoItem = ({
  label,
  oldValue,
  newValue,
  isStatus = false,
  textVariant = 'h5',
}: TicketDetailsInfoItemProps) => {
  return (
    <Typography variant={textVariant}>
      {label && `${label}: `}
      {oldValue && oldValue !== newValue && (
        <Typography variant={textVariant} component="span" sx={styles.oldValue}>
          {oldValue}{' '}
        </Typography>
      )}
      {newValue}
      {isStatus && <Typography variant={textVariant}>{newValue}</Typography>}
    </Typography>
  );
};

export default TicketDetailsInfoItem;
