import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import { Customer } from '../../../@types/api';
import { LS_KEYS, QUERY_KEYS } from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';
import { useEffect } from 'react';
import usePermissions from '../../../hooks/usePermissions';
import { filterTotalPaymentsSchema, FilterTotalPaymentsData } from '../../../schema';
import { zodResolver } from '@hookform/resolvers/zod';

export const styles = {
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  gap: '2',
};

const defaultValues = {
  type: 'player',
  managerIds: [],
  agentIds: [],
  playerIds: [],
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const PAYMENTS_USER_OPTIONS = [
  { id: 'player', name: 'Player', visibleTo: ['Administrator', 'Manager', 'Agent'] },
  { id: 'agent', name: 'Agent', visibleTo: ['Administrator', 'Manager'] },
  { id: 'manager', name: 'Manager', visibleTo: ['Administrator'] },
];

const FilterPaymentReportsTotal = ({ changeQuery, closeModal }: Props) => {
  const { role } = usePermissions();
  const { pathname } = useLocation();
  const page = pathname.split('/').pop();

  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);

  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const getSavedData = () => getPersistData<FilterTotalPaymentsData>(formDataKey) || defaultValues;

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FilterTotalPaymentsData>({
    resolver: zodResolver(filterTotalPaymentsSchema),
    defaultValues: getSavedData(),
  });

  const type = watch('type');

  const filteredOptions = PAYMENTS_USER_OPTIONS.filter((option) => option.visibleTo.includes(role));

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);
    closeModal?.();
  };

  const onFormSubmit = (data: FilterTotalPaymentsData) => {
    setPersistData<FilterTotalPaymentsData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  useEffect(() => {
    reset({ ...defaultValues, type });
  }, [reset, type]);

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Filter {page}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormSelect
            control={control}
            name="type"
            options={filteredOptions}
            defaultValue="player"
            label="Select role"
            required
            error={errors.type}
          />
          <FormAutocomplete<Customer, FilterTotalPaymentsData>
            name="playerIds"
            control={control}
            queryKey={[QUERY_KEYS.customers]}
            apiService="crm"
            url="customer/search"
            multiple
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.userId === value);
              return option ? `${option.firstName} ${option.lastName} (${option.username})` : '';
            }}
            label="Players"
            optionsFilter="userId"
            disabled={type !== 'player'}
          />
          <FormAutocomplete<Customer, FilterTotalPaymentsData>
            name="managerIds"
            control={control}
            queryKey={[QUERY_KEYS.agentSearch, type]}
            apiService="crm"
            multiple
            url="agent/search"
            queryParams={{ roleTypes: ['Manager'] }}
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option.userId === value);
              return option ? `${option.fullName}` : '';
            }}
            label="Managers"
            optionsFilter="userId"
            disabled={type !== 'manager'}
            hookEnabled={type === 'manager'}
          />
          <FormAutocomplete<Customer, FilterTotalPaymentsData>
            name="agentIds"
            control={control}
            queryKey={[QUERY_KEYS.agentSearch, type]}
            apiService="crm"
            url="agent/search"
            multiple
            queryParams={{ roleTypes: ['Agent'] }}
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option.userId === value);
              return option ? `${option.fullName}` : '';
            }}
            optionsFilter="userId"
            label="Agents"
            disabled={type !== 'agent'}
            hookEnabled={type === 'agent'}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleFilterReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterPaymentReportsTotal;
