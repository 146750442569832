import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { capitalize, Dialog, Stack } from '@mui/material';
import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { CommissionPicks, PaginatedData } from '../../@types/api';
import { COMMISSION_PICKS, LS_KEYS, QUERY_KEYS } from '../../constants';
import { createNumberColumn } from '../../helpers/columns';
import usePersist from '../../hooks/usePersist';
import { createColumn } from '../../utils';
import { getData } from '../../utils/api';
import { DayjsTimestamp } from '../molecules/DateRangeSelect';
import { FilterAgentsReportData } from './FilterAgentsReports';
import StyledDataGrid from './StyledDataGrid';
import dayjs from 'dayjs';

const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      borderRadius: 2,
      boxShadow: 0,
      backgroundColor: 'transparent',
    },
  },
  table: {
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'background.light',
      },
    },
    '& .MuiDataGrid-row:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
    '& .MuiDataGrid-columnHeader': {
      '&:last-child': {
        width: '100% !important',
        maxWidth: 'unset !important',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      '> div': {
        width: '100%',
      },
    },
  },
  icon: {
    alignSelf: 'flex-end',
    color: 'primary.container',
    cursor: 'pointer',
  },
};

type Props = {
  agentId: string;
  open: boolean;
  closeModal: () => void;
  agentName: string;
  dateRangeFilter: DayjsTimestamp;
};

type AgentReportDetailsResponse = CommissionPicks<number> & {
  total: number;
  name: string;
};

const skipFormattingForTicketsRow = (params: GridValueFormatterParams<AgentReportDetailsResponse>) => {
  const rowName = params.api.getRow(params.id || '').name;

  return rowName === 'tickets';
};

const AgentReportsDetails = ({ agentId, open, closeModal, agentName, dateRangeFilter }: Props) => {
  const { pathname } = useLocation();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;

  const { getPersistData } = usePersist(false);
  const savedFilterData = getPersistData<FilterAgentsReportData>(formDataKey);

  const { data } = useQuery([QUERY_KEYS.agentsReportDetails], {
    queryFn: (): Promise<PaginatedData<AgentReportDetailsResponse>> =>
      getData(`reports/partners/agents/${agentId}`, {
        fromTimestamp: dayjs(dateRangeFilter.fromTimestamp).valueOf(),
        toTimestamp: dayjs(dateRangeFilter.toTimestamp).valueOf(),
        ...savedFilterData,
      }),
    enabled: !!agentId,
  });

  const columns: GridColDef[] = [
    createColumn('', `Partner: ${agentName}`, { sortable: false, flex: 1.5 }),
    createColumn('name', 'Per Pick', {
      sortable: false,
      valueFormatter: (params) => capitalize(params.value),
      flex: 1.5,
    }),
    ...COMMISSION_PICKS.map((pick) =>
      createNumberColumn(pick, pick, {
        sortable: false,
        skipFormatting: skipFormattingForTicketsRow,
      })
    ),
    createNumberColumn('total', 'Totals', {
      sortable: false,
      skipFormatting: skipFormattingForTicketsRow,
    }),
  ];

  return (
    <Dialog sx={styles.dialog} open={open} fullWidth maxWidth="xl">
      <Stack>
        <CancelRoundedIcon fontSize="large" sx={styles.icon} onClick={closeModal} />
        <StyledDataGrid
          columns={columns}
          rows={data?.items || []}
          getRowId={(params) => params.name}
          sx={styles.table}
        />
      </Stack>
    </Dialog>
  );
};

export default AgentReportsDetails;
