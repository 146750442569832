import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import isEmpty from 'lodash-es/isEmpty';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import { Partner } from '../../../@types/api';
import { LS_KEYS, QUERY_KEYS } from '../../../constants';
import { USER_ROLES } from '../../../constants/keycloak';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePermissions from '../../../hooks/usePermissions';
import usePersist from '../../../hooks/usePersist';
import FormAutocomplete from '../FormAutocomplete';

type FilterPlayerReportData = {
  managerIds?: string[];
  agentIds?: string[];
};

const defaultValues: FilterPlayerReportData = {
  managerIds: undefined,
  agentIds: undefined,
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterPlayerReport = ({ changeQuery, closeModal }: Props) => {
  const { role } = usePermissions();
  const { pathname } = useLocation();
  const page = pathname.split('/').pop();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const getSavedData = () => getPersistData<FilterPlayerReportData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset, watch, setValue } = useForm<FilterPlayerReportData>({
    defaultValues: getSavedData(),
  });

  const managerIds = watch('managerIds');
  const agentIds = watch('agentIds');

  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'managerIds') {
        if (!isEmpty(agentIds)) {
          setValue('agentIds', []);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, agentIds]);

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(defaultValues);
    closeModal?.();
  };

  const onFormSubmit = (data: FilterPlayerReportData) => {
    const queryParams = { ...data };

    // When filtering by both managers and agents, only include the agentIds query parameter
    if (!isEmpty(agentIds)) {
      queryParams.managerIds = undefined;
    }

    setPersistData<FilterPlayerReportData>(formDataKey, data);
    changeQuery({ ...queryParams });
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Filter {page}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {role === USER_ROLES.ADMIN && (
            <FormAutocomplete<Partner, FilterPlayerReportData>
              name="managerIds"
              control={control}
              queryKey={[QUERY_KEYS.agentSearch, managerIds]}
              apiService="crm"
              url="agent/search"
              queryParams={{
                roleTypes: ['Manager'],
              }}
              getOptionLabel={(options, value) => {
                const option = options.find((option) => option?.id === value);
                return option ? option.fullName : '';
              }}
              label="Managers"
              multiple
              hookEnabled
            />
          )}
          <FormAutocomplete<Partner, FilterPlayerReportData>
            name="agentIds"
            control={control}
            queryKey={[QUERY_KEYS.agentSearch, managerIds, agentIds]}
            apiService="crm"
            url="agent/search"
            queryParams={{
              roleTypes: ['Agent'],
              filters: {
                teamLeadId: {
                  values: managerIds,
                },
              },
            }}
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.id === value);
              return option ? option.fullName : '';
            }}
            label="Agents"
            multiple
            hookEnabled
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleFilterReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterPlayerReport;
