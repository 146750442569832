import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogType } from '../../../@types';
import { Competition, PaginatedData, Participant, Sport, Tournament } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import useMutateData from '../../../hooks/useMutateData';
import { ParticipantFormData, participantFormSchema } from '../../../schema';
import { getData } from '../../../utils/api';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';

const DEFAULT_FORM_DATA = {
  sportId: '',
  competitionIds: [],
  tournamentIds: [],
  name: '',
  shortName: null,
  isActive: false,
};

type Props = DialogType;

const ParticipantForm = ({ closeModal }: Props) => {
  const { item } = useModal<Participant>();
  const { createData, updateData } = useMutateData('participants', [QUERY_KEYS.participants]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<ParticipantFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(participantFormSchema),
  });

  const sportId = watch('sportId');
  const competitionIds = watch('competitionIds');
  const tournamentIds = watch('tournamentIds');

  const { data: sportsData } = useQuery([QUERY_KEYS.sports], {
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        limit: 100,
        page: 1,
      }),
  });

  const { data: tournamentsData } = useQuery([QUERY_KEYS.tournaments, sportId, competitionIds], {
    queryFn: (): Promise<PaginatedData<Tournament>> =>
      getData('tournaments', {
        limit: 100,
        page: 1,
        sportId,
        competitionIds,
      }),
    enabled: !!competitionIds?.length,
  });

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        sportId: item.sport.id,
        tournamentIds: item.tournaments.map((tournament) => tournament.id),
        competitionIds: item.competitions.map((competition) => competition.id),
      });
    }
  }, [item, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId') {
        setValue('competitionIds', []);
        setValue('tournamentIds', []);
      }

      if (name === 'competitionIds') {
        const validTournamentIds =
          tournamentsData?.items
            ?.filter(
              (tournament) =>
                tournamentIds?.includes(tournament.id) && value.competitionIds?.includes(tournament.competition.id)
            )
            .map((tournament) => tournament.id) || [];

        setValue('tournamentIds', validTournamentIds);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, tournamentsData, tournamentIds]);

  const handleClose = () => {
    reset(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: ParticipantFormData) => {
    if (item) {
      updateData(item.id, data, () => {
        handleClose();
      });
    } else {
      createData(data, () => {
        handleClose();
      });
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={item ? `Edit participant` : 'Add new participant'}
      onClose={handleClose}
      isEdit={!!item}
    >
      <TextField error={!!errors.name} helperText={errors.name?.message} label="Name" {...register('name')} required />
      <TextField
        error={!!errors.shortName}
        helperText={errors.shortName?.message}
        label="Short name"
        {...register('shortName')}
      />
      <FormSelect
        label="Sport"
        name="sportId"
        required
        control={control}
        error={errors.sportId}
        options={sportsData?.items || []}
      />
      <FormAutocomplete<Competition, ParticipantFormData>
        name="competitionIds"
        control={control}
        label="Competition"
        url="competitions"
        queryKey={[QUERY_KEYS.competitions, sportId]}
        queryParams={{
          sportId: sportId,
          isActive: true,
          competitionIds,
        }}
        disabled={!sportId}
        required
        error={errors.competitionIds}
        hookEnabled={!!sportId}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option?.name || '';
        }}
        multiple
      />
      <FormSelect
        label="Tournament"
        name="tournamentIds"
        control={control}
        error={errors.tournamentIds}
        options={tournamentsData?.items || []}
        multiple
        disabled={!competitionIds?.length}
        required
      />
      <FormFieldStack label="Status">
        <Controller name="isActive" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
    </FormModalLayout>
  );
};

export default ParticipantForm;
