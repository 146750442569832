import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DialogType } from '../../../@types';
import { PaginatedData, Participant, Player, SpecialValue } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import useMutateData from '../../../hooks/useMutateData';
import { AddNewSpecialValueFormData, addNewSpecialValueSchema } from '../../../schema';
import { getData } from '../../../utils/api';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormSelect from '../../molecules/FormSelect';

const defaultValues = {
  values: [],
  isActive: false,
  participantId: undefined,
  playerId: undefined,
};

export type SpecialValueFormItem = {
  eventId: string;
  participants: Participant[];
  marketTypeId: string;
  specialValues: SpecialValue[];
};

type Props = DialogType & {
  item: SpecialValueFormItem;
};

const SpecialValueForm = ({ item, closeModal }: Props) => {
  const { createData } = useMutateData('markets', [QUERY_KEYS.events]);

  const modelType = item?.specialValues[0].modelType;

  const valuesToOmit: Record<string, true> = {
    participantId: true,
    playerId: true,
  };

  if (modelType === 'participant') {
    delete valuesToOmit.participantId;
  }

  if (modelType === 'player') {
    delete valuesToOmit.participantId;
    delete valuesToOmit.playerId;
  }

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<AddNewSpecialValueFormData>({
    defaultValues,
    resolver: zodResolver(addNewSpecialValueSchema.omit(valuesToOmit)),
  });

  const participantId = watch('participantId');

  const { data: playersData } = useQuery([QUERY_KEYS.players, participantId], {
    queryFn: (): Promise<PaginatedData<Player>> =>
      getData('players', {
        limit: 1000,
        page: 1,
        participantId,
      }),
    enabled: !!participantId && modelType === 'player',
  });

  const { fields } = useFieldArray({
    control,
    name: 'values',
  });

  useEffect(() => {
    if (item) {
      reset({
        values: item.specialValues.map(() => ({
          id: '',
        })),
        isActive: false,
      });
    }
  }, [item, reset]);

  const handleClose = () => {
    closeModal?.();
  };

  const onFormSubmit = (data: AddNewSpecialValueFormData) => {
    createData(
      {
        eventId: item?.eventId,
        marketTypeId: item?.marketTypeId,
        modelId: modelType === 'player' ? data.playerId : data.participantId,
        values: data.values.map((value) => value.id),
        isActive: data.isActive,
      },
      () => {
        handleClose();
      }
    );
  };

  const playersOptions =
    playersData?.items.map((player) => ({
      id: player.id,
      name: `${player.firstName} ${player.lastName}`,
    })) || [];

  return (
    <FormModalLayout onSave={handleSubmit(onFormSubmit)} label="Add new special value" onClose={handleClose}>
      {modelType && (
        <FormSelect
          name="participantId"
          control={control}
          options={item?.participants}
          error={errors.participantId}
          label="Participant"
        />
      )}
      {modelType === 'player' && (
        <FormSelect
          label="Player"
          name="playerId"
          control={control}
          error={errors.playerId}
          options={playersOptions}
          disabled={!participantId}
        />
      )}
      {fields.map((field, index) => (
        <TextField
          key={field.id}
          error={!!errors.values?.[index]?.id}
          helperText={errors.values?.[index]?.id?.message}
          label="Special Value"
          {...register(`values.${index}.id` as const)}
        />
      ))}
      <FormFieldStack label="Status">
        <Controller name="isActive" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
    </FormModalLayout>
  );
};

export default SpecialValueForm;
