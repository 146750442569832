import {
  Box,
  Button,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { StyleObj } from '../../@types';
import { SportEventPart, SportEventPartsResponse } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import useMutateData from '../../hooks/useMutateData';
import { SportEventPartsFormData } from '../../schema';
import { getData } from '../../utils/api';
import Switch from '../atoms/Switch';
import FormNumberInput from '../molecules/FormNumberInput';

const defaultValues = {
  sportEventParts: [],
};

const styles: StyleObj = {
  table: {
    '& .MuiTableCell-root ': {
      borderColor: 'primary.lightBorder',
    },
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    mb: 2,
  },
  headCell: {
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: 'background.lightGreen',
    p: 1,
    textAlign: 'center',
  },
  inputCell: {
    padding: 0.5,
    fontSize: 14,
  },
  input: {
    padding: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
      padding: 0,
      textAlign: 'center',
    },
    '& input::placeholder': {
      fontSize: 14,
      fontStyle: 'italic',
    },
  },
  switchWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  numberInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      fontSize: 14,
    },
  },
};

const SportEventPartsTab = () => {
  const { item, closeModal } = useModal<SportEventPart>();
  const sportId = item?.id;

  const { data: sportEventParts, refetch } = useQuery([QUERY_KEYS.eventParts, sportId], {
    queryFn: (): Promise<SportEventPartsResponse> =>
      getData('sport-event-parts', {
        sportId,
      }),
    enabled: !!sportId,
    select: (data) => data.items,
  });

  const { updateData } = useMutateData('sport-event-parts', [QUERY_KEYS.eventPartScores, sportId]);
  const {
    handleSubmit,
    reset,
    register,
    control,
    formState: { errors, isDirty },
  } = useForm<SportEventPartsFormData>({
    defaultValues,
  });

  const { fields } = useFieldArray({
    control,
    name: 'sportEventParts',
  });

  useEffect(() => {
    if (sportEventParts) {
      reset({
        sportEventParts: sportEventParts.map((sportEventPart) => ({
          ...sportEventPart,
          shortName: sportEventPart.shortName || '',
        })),
      });
    }
  }, [reset, sportEventParts]);

  const handleClose = () => {
    closeModal?.();
    reset(defaultValues);
  };

  const onFormSubmit = (data: SportEventPartsFormData) => {
    const payload = {
      sportEventParts: data.sportEventParts.reduce<
        { id: string; isActive?: boolean; position?: number | null; shortName?: string | null }[]
      >((acc, eventPart) => {
        const originalEventPart = sportEventParts?.find((sportEventPart) => sportEventPart.id === eventPart.id);

        const isActiveChanged = originalEventPart?.isActive !== eventPart.isActive;
        const positionChanged = originalEventPart?.position !== eventPart.position;
        const shortNameChanged = (originalEventPart?.shortName || '') !== eventPart.shortName;

        if ((originalEventPart && isActiveChanged) || positionChanged || shortNameChanged) {
          acc.push({
            id: eventPart.id,
            isActive: isActiveChanged ? eventPart.isActive : undefined,
            position: positionChanged ? eventPart.position : undefined,
            shortName: shortNameChanged ? eventPart.shortName : undefined,
          });
        }
        return acc;
      }, []),
    };

    updateData('', payload, () => {
      refetch();
      handleClose();
    });
  };

  return (
    <>
      <TableContainer sx={styles.tableContainer}>
        <Table border={1} sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.headCell}>Name</TableCell>
              <TableCell sx={styles.headCell}>Short name</TableCell>
              <TableCell sx={styles.headCell}>Add to scoreboard</TableCell>
              <TableCell sx={styles.headCell}>Position</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              return (
                <TableRow key={field.id}>
                  <TableCell align="center">{field.eventPartName}</TableCell>
                  <TableCell sx={styles.inputCell}>
                    <TextField
                      {...register(`sportEventParts.${index}.shortName`)}
                      placeholder="Short name"
                      error={!!errors.sportEventParts?.[index]?.shortName}
                      sx={styles.input}
                    />
                  </TableCell>
                  <TableCell sx={styles.inputCell} width={80}>
                    <Box sx={styles.switchWrapper}>
                      <Controller
                        name={`sportEventParts.${index}.isActive`}
                        control={control}
                        render={({ field }) => <Switch {...field} />}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="center" sx={styles.inputCell} width={80}>
                    <FormNumberInput
                      name={`sportEventParts.${index}.position`}
                      control={control}
                      sx={styles.numberInput}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onFormSubmit)} disabled={!isDirty}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default SportEventPartsTab;
