import { Replay } from '@mui/icons-material';
import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel, GridValueFormatterParams } from '@mui/x-data-grid';
import { useState } from 'react';
import { StyleObj } from '../../@types';
import { useModal } from '../../contexts/ModalContext';
import usePersist from '../../hooks/usePersist';

export type AdditionalDataGridColumnProps = {
  alwaysVisible?: boolean;
  skipFormatting?: (params: GridValueFormatterParams) => boolean;
};

type CustomGridColDef = GridColDef & AdditionalDataGridColumnProps;

type CustomizeTableProps = {
  columns: CustomGridColDef[];
  visibleColumns: GridColumnVisibilityModel;
  setVisibleColumns: React.Dispatch<React.SetStateAction<GridColumnVisibilityModel>>;
  defaultVisibleColumns: GridColumnVisibilityModel;
  visibleColumnsLSKey: string;
};

const styles: StyleObj = {
  formControlLabel: (theme) => ({
    '.MuiFormControlLabel-label': { ...theme.typography.h6 },
  }),
  checkList: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
};

const CustomizeTable = ({
  columns,
  visibleColumns,
  setVisibleColumns,
  defaultVisibleColumns,
  visibleColumnsLSKey,
}: CustomizeTableProps) => {
  const { setPersistData, deletePersistData } = usePersist(false);
  const [visibleColumnsLocal, setVisibleColumnsLocal] = useState<GridColumnVisibilityModel>(visibleColumns);
  const { closeModal } = useModal();

  const handleSave = () => {
    setPersistData(visibleColumnsLSKey, visibleColumnsLocal);
    setVisibleColumns(visibleColumnsLocal);
    closeModal?.();
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setVisibleColumnsLocal((prev) => ({
      ...prev,
      [field]: e.target.checked,
    }));
  };

  const handleReset = () => {
    setVisibleColumns(defaultVisibleColumns);
    setVisibleColumnsLocal(defaultVisibleColumns);
    deletePersistData(visibleColumnsLSKey);
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant="h4">Customize table</DialogTitle>
      <DialogContent sx={styles.checkList}>
        {columns.map((column) => {
          if (column.field === 'edit') return null;

          const isChecked = column.alwaysVisible ?? visibleColumnsLocal[column.field];

          return (
            <FormControlLabel
              key={column.field}
              control={<Checkbox checked={isChecked} onChange={(e) => handleCheck(e, column.field)} disableRipple />}
              label={column.headerName}
              sx={styles.formControlLabel}
              disabled={column.alwaysVisible}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default CustomizeTable;
