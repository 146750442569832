import type { GridColDef } from '@mui/x-data-grid';
import type { Sport, SportList } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { createEditColumn, createIsActiveColumn } from '../../helpers/columns';
import { defaultColumnsSports } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import IconRenderer from '../atoms/IconRenderer';
import TableIconContainer from '../atoms/TableIconContainer';
import TableTemplate from '../templates/TableTemplate';

const SportsPage = () => {
  const {
    data: sportsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<SportList>('sports');
  const { deleteData, updateData } = useMutateData('sports', [QUERY_KEYS.sports]);
  const { handleSort } = useSort(changeQuery);

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const columns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('icon', 'Icon', {
      sortable: false,
      renderCell: (params) => {
        return (
          <TableIconContainer>
            <IconRenderer name={params.value} type="sport" />
          </TableIconContainer>
        );
      },
    }),
    createColumn('name', 'Sport Name'),
    createColumn('shortName', 'Sport Short Name'),
    createColumn('position', 'Position'),
    createIsActiveColumn<Sport>({
      updateData,
    }),
    createEditColumn<Sport>({
      deleteData,
    }),
  ];

  return (
    <TableTemplate
      rows={sportsData?.items || []}
      columns={columns}
      loading={isFetching || isLoading}
      rowCount={sportsData?.count || 0}
      createItemBtnLabel="Add new sport"
      defaultVisibleColumns={defaultColumnsSports}
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
      formType="sports"
      showExport
    />
  );
};

export default SportsPage;
