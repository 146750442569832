import { MY_WALLET_TABS } from '../../../../constants';
import WalletLayoutPage from './WalletLayoutPage';

const MyWalletLayoutPage = () => {
  return (
    <WalletLayoutPage
      userType="partner"
      tabs={MY_WALLET_TABS}
      buttonLabel="Add Credit"
      form="add-admin-credits"
      myWallet
    />
  );
};

export default MyWalletLayoutPage;
