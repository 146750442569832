import { Box, SelectChangeEvent } from '@mui/material';
import { GridCellParams, GridColDef, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState } from 'react';
import { LiabilityEventDetails } from '../../@types';
import { LiabilityMonitoringEvent } from '../../@types/api';
import { TICKET_MONITORING_REFETCH_INTERVAL_OPTIONS } from '../../constants';
import { defaultColumnsLiabilityMonitoring } from '../../helpers/table';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import IconRenderer from '../atoms/IconRenderer';
import SelectRefreshInterval from '../atoms/SelectRefreshInterval';
import TableIconContainer from '../atoms/TableIconContainer';
import LiabilityEvent from '../molecules/LiabilityEvent';
import TableTemplate from '../templates/TableTemplate';

const styles = {
  container: {
    display: 'flex',
    height: '100%',
    minHeight: 0,
    gap: '1.5rem',
    position: 'relative',
    width: '100%',
  },
  select: {
    position: 'absolute',
    right: 20,
    top: -15,
  },
};

const INITIAL_QUERY_PARAMS = {
  product: 'pre-match',
  page: 1,
  limit: 25,
  fromTimestamp: dayjs().startOf('day').valueOf(),
  toTimestamp: dayjs().startOf('day').add(3, 'day').valueOf(),
  sort: 'topLiability',
  order: 'DESC',
};

const LiabilityMonitoringPage = () => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(0);
  const [selectedRow, setSelectedRow] = useState<LiabilityEventDetails | null>(null);

  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination<LiabilityMonitoringEvent>(
    'risk-management/monitoring/events',
    INITIAL_QUERY_PARAMS,
    { refetchInterval }
  );

  const handleRefetchIntervalChange = (e: SelectChangeEvent<number | false>) => {
    setRefetchInterval(e.target.value as number | false);
  };

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  const { handleSort } = useSort(changeQuery);

  const onCellClick = (params: GridCellParams, _event: MuiEvent<React.MouseEvent>) => {
    if (params.field !== 'edit' && params.field !== 'isActive' && params.field !== '__check__') {
      setSelectedRow({
        id: params.row.id,
        startDate: params.row.startDate,
        eventName: params.row.name,
        competitionName: params.row.tournament.competition.name,
        sportName: params.row.tournament.competition.sport.name,
        tournamentName: params.row.tournament.name,
      });
    }
  };

  const handleCloseSelectedRow = () => {
    setSelectedRow(null);
  };

  const conditionTypeColumns: GridColDef[] = [
    createColumn('sportId', 'Sport ID', {
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.tournament.competition.sport.id,
    }),
    createColumn('sportIcon', 'Icon', {
      renderCell: (params) => (
        <TableIconContainer>
          <IconRenderer name={params.row.tournament.competition.sport.icon} type="sport" />
        </TableIconContainer>
      ),
    }),
    createColumn('sportName', 'Name', {
      alwaysVisible: true,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.tournament.competition.sport.name,
    }),
    createColumn('competitionId', 'Competition ID', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.tournament.competition.id,
    }),
    createColumn('competitionName', 'Competition', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.tournament.competition.name,
    }),
    createColumn('tournamentId', 'Tournament ID', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.tournament.id,
    }),
    createColumn('tournamentName', 'Tournament', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.tournament.name,
    }),
    createColumn('uniqueId', 'Unique ID', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.id + row.liability,
    }),
    createColumn('eventId', 'Event ID', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.id,
    }),
    createColumn('eventName', 'Event', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.name,
    }),
    createColumn('eventDate', 'Start Date', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) =>
        dayjs(row.startDate).format('DD.MM.YYYY'),
    }),
    createColumn('limitId', 'Limit ID', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.id,
    }),
    createColumn('limitValue', 'Limit Value', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.liability,
    }),
    createColumn('topLiability', 'Top Liability', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.liabilityMax?.toFixed(2),
    }),
    createColumn('liabilityPercentage', 'Liability Percentage', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => {
        if (row.liabilityTotal && row.liability) {
          return (row.liabilityTotal ? (Number(row?.liabilityMax) / row.liability.value) * 100 : 0).toFixed(2) + '%';
        }
      },
      sortable: false,
    }),
    createColumn('betsCount', 'No. Ticket Bets', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.betsCount,
    }),
    createColumn('riskFactor', 'Risk Factor', {
      flex: 2,
      valueGetter: ({ row }: GridValueGetterParams<LiabilityMonitoringEvent>) => row.riskFactor,
    }),
  ];

  return (
    <Box sx={styles.container}>
      <Box sx={styles.select}>
        <SelectRefreshInterval
          handleRefetchIntervalChange={handleRefetchIntervalChange}
          options={TICKET_MONITORING_REFETCH_INTERVAL_OPTIONS}
          value={refetchInterval}
        />
      </Box>
      <TableTemplate
        rows={data?.items || []}
        rowCount={data?.count || 0}
        columns={conditionTypeColumns}
        defaultVisibleColumns={defaultColumnsLiabilityMonitoring}
        loading={isFetching || isLoading}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        changeQuery={changeQuery}
        onCellClick={onCellClick}
      />
      {selectedRow && <LiabilityEvent eventDetails={selectedRow} onClose={handleCloseSelectedRow} />}
    </Box>
  );
};

export default LiabilityMonitoringPage;
