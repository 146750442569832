import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from 'neofusion-fe-shared';
import { RouterProvider } from 'react-router-dom';
import ReactQueryProvider from './ReactQueryProvider';
import router from './Routes';
import { KEYCLOAK_CONFIG, KEYCLOAK_INIT_OPTIONS } from './constants/keycloak';
import { AudioProvider } from './contexts/AudioContext';
import { ToastProvider } from './contexts/ToastContext';
import theme from './styles/theme';

const App = () => {
  return (
    <AuthProvider keycloakConfig={KEYCLOAK_CONFIG} keycloakInitOptions={KEYCLOAK_INIT_OPTIONS}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ReactQueryProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <AudioProvider src="/ticket-authorization-sound.mp3">
                <CssBaseline />
                <RouterProvider router={router} />
              </AudioProvider>
            </ToastProvider>
          </ThemeProvider>
        </ReactQueryProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
};

export default App;
