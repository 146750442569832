import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogType } from '../../../../@types';
import { Customer } from '../../../../@types/api';
import { CURRENCY_OPTIONS, GENDER_OPTIONS, QUERY_KEYS } from '../../../../constants';
import { useInvalidateQuery } from '../../../../hooks/useInvalidateQuery';
import useMutateData from '../../../../hooks/useMutateData';
import { CustomerFormData, customerSchema, editCustomerSchema } from '../../../../schema';
import FormFieldStack from '../../../atoms/FormFieldStack';
import Switch from '../../../atoms/Switch';
import FormModalLayout from '../../../layouts/FormModalLayout';
import DateTimeSelect from '../../../molecules/DateTimeSelect';
import FormSelect from '../../../molecules/FormSelect';
import PasswordFields from '../../../molecules/PasswordFields';
import { useModal } from '../../../../contexts/ModalContext';

export const DEFAULT_CUSTOMER_DATA: CustomerFormData = {
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: undefined,
  phone: '',
  username: '',
  password: '',
  confirmPassword: '',
  country: '',
  region: '',
  isActive: false,
  address: '',
  zip: '',
  city: '',
  gender: '',
  nationalId: '',
  currency: '',
  language: '',
};

const CustomerForm = ({ closeModal }: DialogType) => {
  const { item: customer } = useModal<Customer>();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CustomerFormData>({
    defaultValues: DEFAULT_CUSTOMER_DATA,
    resolver: zodResolver(!!customer ? editCustomerSchema : customerSchema),
  });

  const invalidateData = useInvalidateQuery();

  useEffect(() => {
    if (customer) {
      reset({ ...customer, dateOfBirth: customer.dateOfBirth ? dayjs(customer.dateOfBirth) : undefined });
    }
  }, [customer, reset]);

  const { createData, updateData } = useMutateData('customer', [QUERY_KEYS.customers], 'crm');

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_CUSTOMER_DATA);
  };

  const onFormSubmit = (newPlayer: CustomerFormData) => {
    if (customer) {
      updateData(customer.id, newPlayer, () => {
        handleClose();
        invalidateData(['customer/search']);
      });
    } else {
      createData({ ...newPlayer, confirmPassword: undefined }, () => {
        handleClose();
        invalidateData(['customer/search']);
      });
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={customer ? 'Edit player' : 'Add new player'}
      onClose={handleClose}
      isEdit={!!customer}
    >
      <TextField
        label="First Name"
        {...register('firstName')}
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        required
      />
      <TextField
        label="Last Name"
        {...register('lastName')}
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
        required
      />
      <TextField
        label="Username"
        {...register('username')}
        error={!!errors.username}
        helperText={errors.username?.message}
        required
        disabled={!!customer}
      />
      <PasswordFields resetPassword={!!customer} errors={errors} register={register} />
      <FormFieldStack label="Status">
        <Controller name="isActive" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <TextField
        label="Email"
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        disabled={!!customer?.email}
      />
      <DateTimeSelect
        label="Date of birth"
        control={control}
        name="dateOfBirth"
        disableTimePicker
        error={errors.dateOfBirth}
      />
      <TextField
        label="Phone Number"
        {...register('phone')}
        error={!!errors.phone}
        helperText={errors.phone?.message}
      />
      <FormSelect name="gender" label="Gender" control={control} options={GENDER_OPTIONS} />
      <TextField
        label="National ID"
        {...register('nationalId')}
        error={!!errors.nationalId}
        helperText={errors.nationalId?.message}
      />
      <TextField
        label="Country"
        {...register('country')}
        error={!!errors.country}
        helperText={errors.country?.message}
      />
      <TextField label="Region" {...register('region')} error={!!errors.region} helperText={errors.region?.message} />
      <TextField label="City" {...register('city')} error={!!errors.city} helperText={errors.city?.message} />
      <TextField
        label="Address"
        {...register('address')}
        error={!!errors.address}
        helperText={errors.address?.message}
      />
      <TextField label="Zip Code" {...register('zip')} error={!!errors.zip} helperText={errors.zip?.message} />
      <FormSelect name="currency" label="Currency" control={control} options={CURRENCY_OPTIONS} />
      <TextField
        label="Language"
        {...register('language')}
        error={!!errors.language}
        helperText={errors.language?.message}
      />
    </FormModalLayout>
  );
};

export default CustomerForm;
