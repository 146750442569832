import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { StyleObj } from '../../../@types';

const styles: StyleObj = {
  container: {
    background: '#fff',
    border: '1px solid rgba(0, 83, 55, 0.20)',
    borderRadius: '6px',
    padding: '8px 12px',
  },
  key: {
    fontWeight: 600,
    mr: 0.5,
  },
  entryWrapper: {
    my: 0.5,
  },
  heading: {
    mt: 1,
  },
};

type CRMUserDetailCardProps = {
  details: { [key: string]: string | number | undefined };
};

const CRMUserDetailCard = ({ details }: CRMUserDetailCardProps) => {
  return (
    <Box sx={styles.container}>
      {details.heading && (
        <Typography variant="subtitle1" sx={styles.heading}>
          {details.heading}
        </Typography>
      )}
      {Object.entries(details).map(
        (entry) =>
          entry[0] !== 'heading' && (
            <Box key={entry[0]} sx={styles.entryWrapper}>
              <Typography component="span" variant="h6" sx={styles.key}>
                {entry[0]}:
              </Typography>
              <Typography component="span" variant="h6">
                {entry[1]}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};

export default CRMUserDetailCard;
