import { SVGProps } from 'react';

const DLetterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" {...props}>
    <rect width={16} height={16} fill="#78B159" rx={8} />
    <path
      fill="#fff"
      d="M5.16 12V4.9h2.58c.774 0 1.42.16 1.94.48.52.313.91.74 1.17 1.28.26.533.39 1.127.39 1.78 0 .72-.142 1.347-.43 1.88a3.036 3.036 0 0 1-1.22 1.24c-.52.293-1.136.44-1.85.44H5.16Zm4.69-3.56c0-.46-.082-.863-.25-1.21a1.88 1.88 0 0 0-.72-.82c-.312-.2-.692-.3-1.14-.3h-1.2v4.68h1.2c.454 0 .838-.1 1.15-.3.314-.207.55-.487.71-.84.168-.36.25-.763.25-1.21Z"
    />
  </svg>
);
export default DLetterIcon;
