import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogType } from '../../../@types';
import { Event } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import { getLatestValue } from '../../../helpers';
import useMutateData from '../../../hooks/useMutateData';
import { EditEventFormData, editEventFormSchema } from '../../../schema';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import DateTimeSelect from '../../molecules/DateTimeSelect';

const DEFAULT_EVENT_FORM_DATA = {
  name: '',
  startDate: undefined,
  information: null,
  featured: false,
};

type Props = DialogType;

const EditEventForm = ({ closeModal }: Props) => {
  const { createData: createEventChanges } = useMutateData('event-changes', [QUERY_KEYS.events]);
  const { item } = useModal<Event>();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EditEventFormData>({
    defaultValues: DEFAULT_EVENT_FORM_DATA,
    resolver: zodResolver(editEventFormSchema),
  });

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_EVENT_FORM_DATA);
  };

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        name: getLatestValue(item, 'name'),
        startDate: dayjs(getLatestValue(item, 'startDate')),
        information: getLatestValue(item, 'information'),
        featured: getLatestValue(item, 'featured'),
      });
    }
  }, [item, reset]);

  const onFormSubmit = (data: EditEventFormData) => {
    if (item) {
      const payload = isLive
        ? {
            information: data.information,
          }
        : {
            name: data.name,
            startDate: data.startDate?.toISOString(),
            information: data.information,
            featured: data.featured,
          };

      createEventChanges(
        {
          eventId: item.id,
          ...payload,
        },
        () => handleClose()
      );
    }
  };

  const isLive = item?.isLive;

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label="Edit event"
      onClose={handleClose}
      isEdit
      editEntity={isLive ? 'liveEvents' : 'events'}
    >
      <TextField
        error={!!errors.name}
        helperText={errors.name?.message}
        label="Match name"
        {...register('name')}
        disabled
      />
      <DateTimeSelect control={control} name="startDate" error={errors.startDate} disablePast disabled={isLive} />
      <TextField
        error={!!errors.information}
        helperText={errors.information?.message}
        label="Information"
        rows={3}
        multiline
        {...register('information')}
      />
      {!isLive && (
        <FormFieldStack label="Featured">
          <Controller name="featured" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
        </FormFieldStack>
      )}
    </FormModalLayout>
  );
};

export default EditEventForm;
