import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import {
  LS_KEYS,
  PAYMENT_STATUS_OPTIONS,
  PAYMENT_TRANSACTION_TYPE_OPTIONS,
  WALLET_TRANSACTION_TYPE_OPTIONS,
} from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import FormSelect from '../../molecules/FormSelect';

const DEFAULT_FORM_DATA = {
  type: undefined,
  status: undefined,
};

type WalletTransactionFormData = {
  type: string;
  status: string;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterWalletTransactions = ({ closeModal, changeQuery }: Props) => {
  // TODO: Move the filtering and persisting logic into a reusable hook since we are using it in multiple components
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const { pathname } = useLocation();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;

  const getSavedData = () => getPersistData<WalletTransactionFormData>(formDataKey) || DEFAULT_FORM_DATA;

  const { control, handleSubmit } = useForm<WalletTransactionFormData>({
    defaultValues: getSavedData(),
  });

  const onFormSubmit = (data: WalletTransactionFormData) => {
    setPersistData<WalletTransactionFormData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  const handleResetFilter = () => {
    deletePersistData(formDataKey);
    changeQuery(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const isPlayerWallet = pathname.includes('players');
  const isPlayerWalletTransaction = isPlayerWallet && pathname.includes('wallet-transaction');

  let transactionTypeOptions;

  if (isPlayerWalletTransaction) {
    transactionTypeOptions = WALLET_TRANSACTION_TYPE_OPTIONS;
  } else {
    transactionTypeOptions = isPlayerWallet
      ? PAYMENT_TRANSACTION_TYPE_OPTIONS
      : PAYMENT_TRANSACTION_TYPE_OPTIONS.filter((option) => option.id !== 'bonus');
  }

  return (
    <FilterDialogLayout
      label="transactions"
      onClose={() => {
        closeModal?.();
      }}
      onSave={handleSubmit(onFormSubmit)}
      onReset={handleResetFilter}
    >
      <FormSelect name="type" label="Type" control={control} options={transactionTypeOptions} />
      <FormSelect name="status" label="Status" control={control} options={PAYMENT_STATUS_OPTIONS} />
    </FilterDialogLayout>
  );
};

export default FilterWalletTransactions;
