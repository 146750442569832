import { zodResolver } from '@hookform/resolvers/zod';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DialogType } from '../../../../../@types';
import { QUERY_KEYS } from '../../../../../constants';
import { useInvalidateQuery } from '../../../../../hooks/useInvalidateQuery';
import useMutateData from '../../../../../hooks/useMutateData';
import { handleCreditsSchema } from '../../../../../schema';
import FormNumberInput from '../../../../molecules/FormNumberInput';

const DEFAULT_FORM_DATA: AddAdminCreditFormData = {
  amount: '',
};

type AddAdminCreditFormData = {
  amount: string;
};

type Props = DialogType & {
  item: string;
};

const AddAdminCreditsForm = ({ closeModal, item: userId }: Props) => {
  const invalidateData = useInvalidateQuery();

  const { createData } = useMutateData('wallets/credits', [QUERY_KEYS.credits, userId], 'wallet');
  const { control, handleSubmit } = useForm<AddAdminCreditFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(handleCreditsSchema.pick({ amount: true })),
  });

  const handleClose = () => {
    closeModal?.();
  };

  const onFormSubmit = (creditsData: AddAdminCreditFormData) => {
    const preparedData = {
      amount: Number(creditsData.amount),
    };

    createData(
      preparedData,
      () => {
        invalidateData([QUERY_KEYS.userBalance]);
        invalidateData([QUERY_KEYS.adminTransactions]);
        handleClose();
      },
      `Credits added successfully`
    );
  };

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Add Credits
      </DialogTitle>
      <DialogContent>
        <FormNumberInput
          name="amount"
          control={control}
          label="Amount"
          allowDecimals
          valueAsString
          required
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onFormSubmit)}>Add</Button>
      </DialogActions>
    </>
  );
};

export default AddAdminCreditsForm;
