import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Fragment, useEffect } from 'react';
import { UseFieldArrayUpdate, useFieldArray, useForm } from 'react-hook-form';
import { StyleObj } from '../../../../@types';
import { ConditionEntityType, ConditionGroup, ConditionTemplate, ConditionValue } from '../../../../@types/api';
import { CONDITIONS_COMBINATION_MESSAGE, QUERY_KEYS } from '../../../../constants';
import {
  ConditionFormData,
  ConditionTemplateFormData,
  conditionSchema,
  conditionTemplateSchema,
} from '../../../../schema';
import { getData } from '../../../../utils/api';
import FormNumberInput from '../../../molecules/FormNumberInput';
import FormSelect from '../../../molecules/FormSelect';

const styles: StyleObj = {
  formRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
    alignItems: 'center',
    mb: 1,
  },
  formFieldsWrapper: {
    display: 'flex',
    gap: 2,
  },
  textField: { width: 141 },
  buttonsWrapper: {
    display: 'flex',
    gap: 1,
    justifyContent: 'flex-end',
    mt: 3,
  },
  conditionsCombinationLabel: {
    backgroundColor: 'background.lightGreen',
    border: '1px solid rgba(0, 83, 55, 0.20)',
    textAlign: 'center',
    p: 1.25,
    mb: 1,
  },
  tabs: {
    marginBottom: 2,
    '& .MuiButtonBase-root': {
      flex: 1,
    },
  },
};
type FormDataUnion = ConditionFormData | ConditionTemplateFormData;

type ConditionsValuesFormProps<T extends FormDataUnion> = {
  onCancel: () => void;
  onSave: (values: T) => void;
  conditionGroupId: string | null;
  conditionModelType?: ConditionEntityType;
  modelId?: string | null;
};

const defaultValues: FormDataUnion = {
  modelId: undefined,
  conditionGroupId: null,
  type: undefined,
  conditions: [],
};

const updateConditionValues = (
  conditions: ConditionValue[] | ConditionTemplate,
  update: UseFieldArrayUpdate<FormDataUnion, 'conditions'>,
  conditionGroupId?: string | null
) => {
  if (conditions) {
    conditions.forEach((condition, index) => {
      update(index, {
        conditionTypeId: condition.conditionType?.id,
        restrictionValue: conditionGroupId === null ? null : condition.restrictionValue,
        verificationValue: conditionGroupId === null ? null : condition.verificationValue,
      });
    });
  }
};

const ConditionsValuesForm = <T extends FormDataUnion>({
  onCancel,
  onSave,
  conditionGroupId,
  conditionModelType,
  modelId,
}: ConditionsValuesFormProps<T>) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<FormDataUnion>({
    defaultValues,
    resolver: zodResolver(modelId ? conditionSchema : conditionTemplateSchema),
  });

  const { fields, update } = useFieldArray({
    control,
    name: 'conditions',
  });

  const conditionGroupFieldValue = watch('conditionGroupId');

  const conditionGroupIdValue = conditionGroupFieldValue || conditionGroupId;

  const { data: conditionsTemplateItems } = useQuery(
    [QUERY_KEYS.condtitionTemplates, { conditionGroupId: conditionGroupFieldValue }],
    {
      queryFn: (): Promise<{ items: ConditionTemplate }> =>
        getData('condition-templates', {
          conditionGroupId: conditionGroupIdValue,
        }),
      select: (data) => data?.items,
      enabled: !!conditionGroupFieldValue,
    }
  );

  const { data: conditions } = useQuery([QUERY_KEYS.conditions, conditionModelType, modelId], {
    queryFn: (): Promise<{ items: ConditionValue[] }> => getData(`conditions/${conditionModelType}/${modelId}`),
    select: (data) => data?.items,
    enabled: !!conditionModelType && !!modelId,
  });

  const { data: conditionGroups } = useQuery([QUERY_KEYS.conditionGroups, { type: conditionModelType }], {
    queryFn: (): Promise<{ items: ConditionGroup[] }> =>
      getData('condition-groups', {
        type: conditionModelType,
        limit: 100,
      }),
    select: (data) => data?.items,
    enabled: !!conditionModelType,
  });

  const conditionsValue = conditionsTemplateItems && conditionGroupIdValue ? conditionsTemplateItems : conditions;
  const selectedConditionGroupDescription =
    conditionGroups?.find((group) => group.id === conditionGroupIdValue)?.description || '';

  // set default values when props are changed
  useEffect(() => {
    if (conditionModelType) {
      setValue('type', conditionModelType);
    }
    if (modelId) {
      setValue('modelId', modelId);
    }
    setValue('conditionGroupId', conditionGroupId);
  }, [setValue, update, conditionModelType, modelId, reset, conditionGroupId]);

  // update condition values based on a conditionGroupId, but only if conditionsTemplateItems are available
  useEffect(() => {
    if (conditionsTemplateItems) {
      setValue('conditions', []);
      updateConditionValues(conditionsTemplateItems, update);
    }
  }, [conditionsTemplateItems, update, setValue]);

  // if conditionGroupId is not set, update condition values based on conditions, if it is set, update based on conditionsTemplateItems
  useEffect(() => {
    if (conditions && !conditionGroupId) {
      updateConditionValues(conditions, update);
    }
  }, [conditions, update, conditionGroupId]);

  // update condition values when conditionGroupId is changed to null
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (conditions && name === 'conditionGroupId' && value.conditionGroupId === null) {
        updateConditionValues(conditions, update, value.conditionGroupId);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, conditions, update, conditionGroupId]);

  const getFieldLabel = (conditionTypeId: string) => {
    return conditionsValue?.find((item) => {
      return item.conditionType.id === conditionTypeId;
    })?.conditionType.name;
  };

  const handleCancel = () => {
    onCancel();
  };

  const showConditionGroupSelect = conditionModelType !== 'ticket' && modelId && conditionGroups;

  return (
    <Fragment>
      {showConditionGroupSelect && (
        <Box sx={styles.formRow}>
          <Typography variant="h5">Conditions group</Typography>
          <Box sx={styles.formFieldsWrapper}>
            <FormSelect
              name="conditionGroupId"
              control={control}
              error={errors.conditionGroupId}
              options={conditionGroups}
              size="small"
              sx={{ width: 300 }}
            />
          </Box>
        </Box>
      )}
      {fields.map((condition, index) => {
        const conditionLabel = getFieldLabel(condition.conditionTypeId);
        const isSameTicketsCondition = conditionLabel?.toLowerCase().includes('same tickets allowed');

        return (
          <Fragment key={condition.id}>
            <Box>
              {condition.conditionTypeId && (conditionLabel === 'Max Odds' || conditionLabel === 'Min Odds') && (
                <Box sx={styles.conditionsCombinationLabel}>
                  <Typography variant="h5">{CONDITIONS_COMBINATION_MESSAGE[conditionLabel]}</Typography>
                </Box>
              )}
            </Box>
            {condition.conditionTypeId && (
              <Box sx={styles.formRow}>
                <Typography variant="h5">{conditionLabel}</Typography>
                <Box sx={styles.formFieldsWrapper}>
                  <FormNumberInput
                    name={`conditions.${index}.restrictionValue` as const}
                    control={control}
                    error={errors.conditions?.[index]?.restrictionValue}
                    sx={styles.textField}
                    size="small"
                    InputProps={{
                      endAdornment: '(R)',
                    }}
                    allowDecimals={!isSameTicketsCondition}
                    valueAsString
                    disabled={!!modelId && !!conditionGroupFieldValue}
                  />
                  <FormNumberInput
                    name={`conditions.${index}.verificationValue` as const}
                    control={control}
                    error={errors.conditions?.[index]?.verificationValue}
                    sx={styles.textField}
                    size="small"
                    InputProps={{
                      endAdornment: '(V)',
                    }}
                    allowDecimals={!isSameTicketsCondition}
                    valueAsString
                    disabled={!!modelId && !!conditionGroupFieldValue}
                  />
                </Box>
              </Box>
            )}
          </Fragment>
        );
      })}
      {selectedConditionGroupDescription && (
        <Box sx={styles.formRow}>
          <Typography variant="h5">Decription</Typography>
          <TextField
            multiline
            id="description"
            type="text"
            fullWidth
            sx={{ width: 300 }}
            value={selectedConditionGroupDescription}
            disabled
          />
        </Box>
      )}
      <Box sx={styles.buttonsWrapper}>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit((data) => onSave(data as T))}>
          Save
        </Button>
      </Box>
    </Fragment>
  );
};

export default ConditionsValuesForm;
