import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useAuth } from 'neofusion-fe-shared';
import { useForm } from 'react-hook-form';
import { DialogType } from '../../../../@types';
import { CUSTOMER_TASK_MESSAGE_TYPE_OPTIONS, QUERY_KEYS } from '../../../../constants';
import useCRMUserType from '../../../../hooks/useCRMUserType';
import useMutateData from '../../../../hooks/useMutateData';
import { CRMTaskFormData, crmTaskSchema } from '../../../../schema';
import FormModalLayout from '../../../layouts/FormModalLayout';
import DateTimeSelect from '../../../molecules/DateTimeSelect';
import FormSelect from '../../../molecules/FormSelect';
import { useInvalidateQuery } from '../../../../hooks/useInvalidateQuery';
import { USER_TYPE_TO_CRM_API_ENTITY_MAP } from '../../../../config/crm';

const DEFAULT_FORM_DATA = {
  scheduledTime: undefined,
  type: 'CALL',
  description: null,
};

type Props = DialogType & {
  item: string;
};

const CRMTaskForm = ({ closeModal, item: userId }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CRMTaskFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(crmTaskSchema),
  });

  const userType = useCRMUserType();
  const apiEntity = USER_TYPE_TO_CRM_API_ENTITY_MAP[userType];
  const invalidateData = useInvalidateQuery();

  const { username } = useAuth();
  const { createData } = useMutateData(`tasks/${apiEntity}`, [QUERY_KEYS.crmTasks, userType], 'crm');

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_FORM_DATA);
  };

  const onFormSubmit = (newTask: CRMTaskFormData) => {
    const payload = {
      ...newTask,
      userId,
      author: username,
    };

    createData(
      payload,
      () => {
        closeModal?.();
        invalidateData([`tasks/${apiEntity}`]);
      },
      'Task created successfully.'
    );
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label="Schedule task"
      onClose={handleClose}
      actionButtonLabel="Submit"
    >
      <DateTimeSelect
        control={control}
        name="scheduledTime"
        label="Time and date"
        error={errors.scheduledTime}
        disablePast
      />
      <FormSelect control={control} name="type" options={CUSTOMER_TASK_MESSAGE_TYPE_OPTIONS} error={errors.type} />
      <TextField
        error={!!errors.comment}
        helperText={errors.comment?.message}
        label="Description"
        multiline
        required
        {...register('comment')}
      />
    </FormModalLayout>
  );
};

export default CRMTaskForm;
