import { Typography } from '@mui/material';
import { StyleObj } from '../../@types';
import StyledMenuItem from '../atoms/StyledMenuItem';

export type HandleMenuItemClickOption = {
  id: number | string;
  name: string;
};

export type HandleMenuItemClickField = 'manualControl' | 'isActive' | 'publishStatus';

type ManageMenuSectionProps = {
  title?: string;
  options: { id: number | string; name: string }[];
  handleItemClick: (option: HandleMenuItemClickOption) => void;
  iconRenderer?: (id: number | string) => JSX.Element;
};

const styles: StyleObj = {
  menuSectionHeader: (theme) => ({ fontWeight: 700, px: 2, py: 0.5, color: theme.palette.primary.dark }),
};

const ManageMenuSection = ({ title, options, handleItemClick, iconRenderer }: ManageMenuSectionProps) => {
  return (
    <>
      {title && (
        <Typography variant="h6" sx={styles.menuSectionHeader}>
          {title}
        </Typography>
      )}
      {options.map((option) => (
        <StyledMenuItem
          key={option.id}
          onClick={() => {
            handleItemClick(option);
          }}
        >
          {iconRenderer && iconRenderer(option.id)}
          {option.name}
        </StyledMenuItem>
      ))}
    </>
  );
};

export default ManageMenuSection;
