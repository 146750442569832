import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DialogType } from '../../../@types';
import { QUERY_KEYS } from '../../../constants';
import useMutateData from '../../../hooks/useMutateData';
import { EventFormData, createOutrightFormSchema } from '../../../schema';
import FormModalLayout from '../../layouts/FormModalLayout';
import DateTimeSelect from '../../molecules/DateTimeSelect';
import SportSelect from '../../molecules/SportSelect';
import TournamentSelect from '../../molecules/TournamentSelect';
import FormAutocomplete from '../FormAutocomplete';

const DEFAULT_EVENT_FORM_DATA = {
  name: '',
  sportId: undefined,
  competitionId: undefined,
  tournamentId: undefined,
  startDate: dayjs().add(1, 'hour'),
  information: null,
};

const CreateOutrightForm = ({ closeModal }: DialogType) => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<EventFormData>({
    defaultValues: DEFAULT_EVENT_FORM_DATA,
    resolver: zodResolver(createOutrightFormSchema),
  });

  const { createData } = useMutateData('outrights', [QUERY_KEYS.outrights]);

  const handleClose = () => {
    reset(DEFAULT_EVENT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (newEvent: EventFormData) => {
    const payload = {
      name: newEvent.name,
      tournamentId: newEvent.tournamentId,
      startDate: newEvent.startDate.set('second', 0).toISOString(),
      information: newEvent.information,
      featured: newEvent.featured,
    };

    createData(payload, () => {
      handleClose();
    });
  };

  const sportId = watch('sportId');
  const competitionId = watch('competitionId');

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId') {
        setValue('competitionId', '');
        setValue('tournamentId', '');
      }

      if (name === 'competitionId') {
        setValue('tournamentId', '');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <FormModalLayout onSave={handleSubmit(onFormSubmit)} label="Add new outright" onClose={handleClose} isEdit={false}>
      <TextField error={!!errors.name} helperText={errors.name?.message} label="Name" {...register('name')} required />
      <SportSelect
        control={control}
        name="sportId"
        required
        closeMenuOnSelect
        error={errors.sportId}
        queryParams={{ isActive: true }}
      />
      <FormAutocomplete
        name="competitionId"
        control={control}
        label="Competitions"
        url="competitions"
        required
        queryKey={[QUERY_KEYS.competitions, sportId]}
        queryParams={{
          sportId: sportId,
          isActive: true,
        }}
        disabled={isEmpty(sportId)}
        hookEnabled={!!sportId}
        error={errors.competitionId}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option?.name || '';
        }}
      />
      <TournamentSelect
        control={control}
        name="tournamentId"
        required
        competitionIds={competitionId && [competitionId]}
        error={errors.tournamentId}
        closeMenuOnSelect
      />
      <DateTimeSelect control={control} name="startDate" error={errors.startDate} disablePast />
      <TextField
        error={!!errors.information}
        helperText={errors.information?.message}
        label="Information"
        rows={3}
        multiline
        {...register('information')}
      />
    </FormModalLayout>
  );
};

export default CreateOutrightForm;
