import { useParams } from 'react-router-dom';
import { customerWalletTransactionColumns } from '../../../../config/wallet';
import WalletTransactionPage from './WalletTransactionPage';

const CustomerWalletTransactionPage = () => {
  const { userId } = useParams();

  return (
    <WalletTransactionPage userId={userId} endpoint="transactions/wallets" columns={customerWalletTransactionColumns} />
  );
};

export default CustomerWalletTransactionPage;
