import { Box } from '@mui/material';
import { StyleObj } from '../../@types';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const styles: StyleObj = {
  tabPanel: {
    width: '100%',
  },
};

const TabPanel = ({ children, value, index, ...rest }: TabPanelProps) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...rest}>
      {value === index && <Box sx={styles.tabPanel}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
