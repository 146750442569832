import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { EntityEditTabType } from '../../config';
import EditTabs from '../organisms/EditTabs';
import { useIsMutating } from '@tanstack/react-query';

export type FormLayoutProps = {
  isEdit?: boolean;
  label: string;
  onSave: () => void;
  children?: ReactNode;
  onClose: () => void;
  actionButtonLabel?: string;
  editEntity?: EntityEditTabType;
  showEditTabs?: boolean;
};

const FormModalLayout = ({
  isEdit = false,
  onSave,
  children,
  label,
  onClose,
  actionButtonLabel,
  editEntity,
  showEditTabs = true,
}: FormLayoutProps) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const isMutating = useIsMutating();

  const handleTabChange = (_event: React.SyntheticEvent, newTabIndex: number) => {
    setSelectedTabIdx(newTabIndex);
  };

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        {label}
      </DialogTitle>
      <DialogContent>
        {isEdit && showEditTabs ? (
          <EditTabs editEntity={editEntity} selectedTab={selectedTabIdx} handleTabChange={handleTabChange}>
            {children}
          </EditTabs>
        ) : (
          <Stack spacing={2}>{children}</Stack>
        )}
      </DialogContent>
      {selectedTabIdx === 0 && (
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSave} disabled={!!isMutating}>
            {actionButtonLabel ?? 'Save'}
          </Button>
        </DialogActions>
      )}
    </>
  );
};

export default FormModalLayout;
