import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DateRangeSelect, { DayjsTimestamp } from '../../../molecules/DateRangeSelect';
import TableTemplate from '../../../templates/TableTemplate';
import { generateDateRangeTimestamps, getTotalsRowClass } from '../../../../helpers';
import dayjs from 'dayjs';
import { usePagination } from '../../../../hooks/usePagination';
import { createColumn } from '../../../../utils';
import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import { StyleObj } from '../../../../@types';
import useSort from '../../../../hooks/useSort';
import { ReportsTotals } from '../../../../@types/api';
import { createNumberColumn } from '../../../../helpers/columns';

const styles: StyleObj = {
  container: {
    position: 'relative',
    height: 'calc(100% - 200px)',
  },
  table: {
    border: 'none',
    '& .MuiDataGrid-columnHeader': {
      border: `1px solid rgba(0, 83, 55, 0.20)`,
      backgroundColor: 'background.lightGreen',
      '&:first-of-type': {
        borderRadius: 0,
      },
      '&:last-of-type': {
        borderRadius: 0,
        width: '100% !important',
        maxWidth: 'unset !important',
      },
      '& .MuiDataGrid-sortIcon': {
        color: 'grey.700',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      '> div': {
        width: '100%',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
      color: 'grey.700',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '& .customGroupHeader': {
      backgroundColor: 'primary.main',
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'center',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
        color: 'white',
      },
      '&:first-of-type': {
        borderTopLeftRadius: 8,
      },
      '&:last-of-type': {
        borderTopRightRadius: 8,
      },
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      '& .MuiDataGrid-row--lastVisible': {
        backgroundColor: '#D9E7D9',
        '& .MuiDataGrid-cell': {
          fontWeight: '700',
        },
      },
    },
  },
};

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Username',
    description: 'Username',
    headerClassName: 'customGroupHeader',
    children: [{ field: 'userName' }],
  },
  {
    groupId: 'Deposits',
    description: 'Deposits',
    headerClassName: 'customGroupHeader',
    children: [
      { field: 'completedDepositsCount' },
      { field: 'canceledDepositsCount' },
      { field: 'totalDepositsCount' },
      { field: 'totalDepositsAmount' },
    ],
  },
  {
    groupId: 'Withdrawals',
    description: 'Withdrawals',
    headerClassName: 'customGroupHeader',
    children: [
      { field: 'completedWithdrawalsCount' },
      { field: 'canceledWithdrawalsCount' },
      { field: 'totalWithdrawalsCount' },
      { field: 'totalWithdrawalsAmount' },
      { field: 'netTransactionsAmount' },
    ],
  },
];

const columns: GridColDef[] = [
  createColumn('userName', '', { sortable: false }),
  createColumn('completedDepositsCount', 'Completed'),
  createColumn('canceledDepositsCount', 'Canceled'),
  createColumn('totalDepositsCount', 'Total'),
  createNumberColumn('totalDepositsAmount', 'Amount'),
  createColumn('completedWithdrawalsCount', 'Completed'),
  createColumn('canceledWithdrawalsCount', 'Canceled'),
  createColumn('totalWithdrawalsCount', 'Total'),
  createNumberColumn('totalWithdrawalsAmount', 'Amount'),
  createNumberColumn('netTransactionsAmount', '+/-'),
];

const generateTotalsRow = (data?: ReportsTotals) => {
  if (data) {
    return {
      ...data,
      userName: 'Totals',
    };
  }
  return { userName: 'Totals:' };
};

const PaymentsTotalReportsPage = () => {
  const { fromTimestamp, toTimestamp } = generateDateRangeTimestamps('today');
  const [date, setDate] = useState<DayjsTimestamp>({
    fromTimestamp: dayjs().startOf('day'),
    toTimestamp: dayjs().endOf('day'),
  });

  const { data, updateQueryParams, isFetching, isLoading, changeQuery } = usePagination<ReportsTotals>(
    `reports/payments/total`,
    {
      page: 1,
      limit: 25,
      fromTimestamp: fromTimestamp.valueOf(),
      toTimestamp: toTimestamp.valueOf(),
      type: 'player',
    }
  );
  const { handleSort } = useSort(changeQuery);

  const totalsRow = generateTotalsRow(data?.totals);

  const aggregatedData = data?.items ? [...data.items, totalsRow] : [];

  const handleDateChange = (newDate: DayjsTimestamp) => {
    setDate(newDate);
  };

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  useEffect(() => {
    changeQuery({
      fromTimestamp: date.fromTimestamp.valueOf(),
      toTimestamp: date.toTimestamp.valueOf(),
    });
  }, [date, changeQuery]);

  return (
    <Box sx={styles.container}>
      <DateRangeSelect date={date} onDateChange={handleDateChange} />
      <TableTemplate
        getRowId={(params) => params.userName}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        rows={aggregatedData}
        columns={columns}
        sx={styles.table}
        loading={isFetching || isLoading}
        rowCount={data?.count || 0}
        handlePaginationModelChange={updateQueryParams}
        handleSearch={handleSearch}
        handleSort={handleSort}
        getRowClassName={getTotalsRowClass}
        changeQuery={changeQuery}
        showExport
      />
    </Box>
  );
};

export default PaymentsTotalReportsPage;
