import { Control, FieldError, FieldValues, Merge, Path } from 'react-hook-form';
import { useSports } from '../../queries';
import FormSelect from '../molecules/FormSelect';

type SportSelectProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  multiple?: boolean;
  required?: boolean;
  closeMenuOnSelect?: boolean;
  queryParams?: Record<string, string | number | boolean>;
};

const SportSelect = <T extends FieldValues>({
  control,
  name,
  label,
  multiple,
  required,
  error,
  closeMenuOnSelect = false,
  queryParams,
}: SportSelectProps<T>) => {
  const { data: sportsData } = useSports({
    limit: 100,
    ...queryParams,
  });

  return (
    <FormSelect
      label={label ?? 'Sport'}
      name={name}
      control={control}
      required={required}
      options={sportsData?.items || []}
      multiple={multiple}
      error={error}
      closeOnSelect={closeMenuOnSelect}
    />
  );
};

export default SportSelect;
