import { SVGProps } from 'react';

const SemiIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none">
      <g clipPath="url(#a)">
        <path fill="#2B7FFE" d="M20 12a8 8 0 1 0-16 0 8 8 0 0 0 16 0Z" />
        <path
          fill="#fff"
          d="m14.483 10.074-1.054.298a1.96 1.96 0 0 0-.293-.512 1.424 1.424 0 0 0-.517-.418c-.22-.109-.5-.164-.84-.164-.468 0-.857.108-1.169.324-.308.212-.462.482-.462.81 0 .292.106.522.318.691.212.169.543.31.994.423l1.134.278c.683.166 1.191.42 1.526.76.335.339.502.774.502 1.308 0 .438-.126.829-.378 1.174-.248.344-.596.616-1.044.815-.447.199-.967.298-1.56.298-.78 0-1.424-.169-1.935-.507-.51-.338-.833-.832-.97-1.481l1.114-.279c.106.411.307.72.602.925.298.205.688.308 1.168.308.547 0 .981-.116 1.303-.348.325-.235.487-.517.487-.845a.888.888 0 0 0-.278-.666c-.186-.183-.471-.319-.855-.408l-1.273-.298c-.7-.166-1.213-.423-1.541-.77-.325-.352-.488-.791-.488-1.318 0-.431.121-.812.363-1.144a2.47 2.47 0 0 1 1-.78 3.51 3.51 0 0 1 1.441-.284c.756 0 1.35.166 1.78.497.434.332.743.77.925 1.313Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M4 4h16v16H4z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SemiIcon;
