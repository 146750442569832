import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { LinkProps, Link as RouterLink, useLocation } from 'react-router-dom';
import { StyleObj } from '../../@types';
import { isUUID } from '../../helpers';
import { formatBreadcrumb } from '../../utils';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const styles: StyleObj = {
  breadcrumbs: {
    textTransform: 'capitalize',
    color: 'text.primary',
    '& .MuiBreadcrumbs-separator': {
      mx: 0.5,
      mb: -0.25,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
    },
  },
  link: {
    fontWeight: 600,
    letterSpacing: '0.1px',
  },
};

const LinkRouter = (props: LinkRouterProps) => {
  return <Link {...props} component={RouterLink} underline="hover" variant="h6" sx={styles.link} />;
};

const Breadcrumbs = () => {
  const location = useLocation();
  const crumbs = window.location.pathname.split('/').filter(Boolean);

  const isHomePage = location.pathname === '/';

  return (
    <MUIBreadcrumbs separator={<ChevronRightIcon />} aria-label="breadcrumbs" sx={styles.breadcrumbs}>
      {!isHomePage && (
        <LinkRouter color="inherit" to="/">
          Dashboard
        </LinkRouter>
      )}
      {crumbs.map((crumb, index) => {
        const to = `/${crumbs.slice(0, index + 1).join('/')}`;

        if (isUUID(crumb)) return null;

        return (
          <LinkRouter color="inherit" to={to} key={to}>
            {formatBreadcrumb(crumb)}
          </LinkRouter>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
