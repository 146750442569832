import { Button, DialogActions, DialogTitle } from '@mui/material';
import { DialogType } from '../../@types';

type DialogDelete = DialogType & {
  handleConfirm: () => void;
  title?: string;
  actionButtonLabel?: string;
};

const ConfirmationModal = ({ title, actionButtonLabel, handleConfirm, closeModal }: DialogDelete) => {
  const onConfirm = () => {
    handleConfirm();
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant="h4" align="center" gutterBottom>
        {title || 'Are you sure you want to delete this item?'}
      </DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          {/* Delete */}
          {actionButtonLabel || 'Delete'}
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmationModal;
