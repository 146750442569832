import { SVGProps } from 'react';

const AutomaticIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" fill="none">
      <path d="M20 12a8 8 0 1 0-16 0 8 8 0 0 0 16 0Z" fill="#2B7FFE" />
      <path
        d="M11.31 8.9h1.4l2.61 7.1H13.9l-.66-1.77h-2.48L10.11 16H8.69l2.62-7.1Zm1.68 4.36-.98-2.93-1.02 2.93h2Z"
        fill="#fff"
      />
    </svg>
  );
};

export default AutomaticIcon;
