import { Box, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  text: string;
}

const ErrorPage: FC<Props> = ({ text }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography fontSize="30px" textAlign="center" mt={5}>
        {text}
      </Typography>
    </Box>
  );
};

export default ErrorPage;
