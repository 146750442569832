import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { Box, Divider, Menu } from '@mui/material';
import { QueryKey } from '@tanstack/react-query';
import { StyleObj } from '../../@types';
import { PublishStatus } from '../../@types/api';
import {
  MARKET_ALL_MATCHES_STATUS_OPTIONS,
  MARKET_STATUS_OPTIONS,
  PUBLISH_STATUS_BASE_OPTIONS,
  QUERY_KEYS,
} from '../../constants';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMutateData from '../../hooks/useMutateData';
import StyledMenuItem from '../atoms/StyledMenuItem';
import PublishStatusIcon from '../icons/PublishStatusIcon';
import ManageMenuSection from './ManageMenuSection';

export type HandleMenuItemClickOption = {
  id: number | string;
  name: string;
};

type ManageMarketMenuProps = {
  contextMenu: { mouseX: number; mouseY: number } | null;
  handleClose: () => void;
  queryKey: QueryKey;
  selectedRows: string[];
};

const styles: StyleObj = {
  menu: {
    '& .MuiMenu-list': {
      pt: 0,
      pb: 0.75,
    },
  },
  menuContent: { minWidth: 156 },
  menuSectionHeader: (theme) => ({ fontWeight: 700, px: 2, py: 0.5, color: theme.palette.primary.dark }),
  menuMuiIcon: { mr: 0.5 },
};

const ManageMarketMenu = ({ contextMenu, handleClose, queryKey, selectedRows }: ManageMarketMenuProps) => {
  const invalidateData = useInvalidateQuery();
  const { updateData } = useMutateData('markets', queryKey);
  const { createData: createMarketChangesDataBulk } = useMutateData('market-changes/bulk', queryKey);
  const { createData: createMarketChangesDataBulkTournament } = useMutateData(
    'market-changes/bulk/tournament',
    queryKey
  );

  const handlePublishStatusUpdate = (option: HandleMenuItemClickOption) => {
    updateData(
      'bulk',
      {
        marketIds: selectedRows,
        publishStatus: option.id,
      },
      () => {
        invalidateData([QUERY_KEYS.events, QUERY_KEYS.markets, QUERY_KEYS.outrights]);
        handleClose();
      }
    );
  };

  const handleIsActiveUpdate = (option: HandleMenuItemClickOption) => {
    createMarketChangesDataBulk(
      {
        marketIds: selectedRows,
        isActive: !!option.id,
      },
      () => {
        invalidateData([QUERY_KEYS.events, QUERY_KEYS.markets, QUERY_KEYS.outrights]);
        handleClose();
      }
    );
  };

  const handleIsActiveTournamentLevelUpdate = (option: HandleMenuItemClickOption) => {
    createMarketChangesDataBulkTournament(
      {
        marketIds: selectedRows,
        isActive: !!option.id,
      },
      () => {
        invalidateData([QUERY_KEYS.events, QUERY_KEYS.markets, QUERY_KEYS.outrights]);
        handleClose();
      }
    );
  };

  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      slotProps={{
        root: {
          onContextMenu: (e) => {
            e.preventDefault();
            handleClose();
          },
        },
      }}
      sx={styles.menu}
    >
      <Box sx={styles.menuContent}>
        <ManageMenuSection
          title="Publish status"
          options={PUBLISH_STATUS_BASE_OPTIONS}
          handleItemClick={handlePublishStatusUpdate}
          iconRenderer={(id) => <PublishStatusIcon status={id as PublishStatus} />}
        />
        <Divider />
        <ManageMenuSection
          title="Market status"
          options={MARKET_STATUS_OPTIONS}
          handleItemClick={handleIsActiveUpdate}
          iconRenderer={(id) =>
            id === 1 ? (
              <CheckCircle color={'success'} fontSize="small" sx={styles.menuMuiIcon} />
            ) : (
              <RemoveCircle color={'error'} fontSize="small" sx={styles.menuMuiIcon} />
            )
          }
        />
        {MARKET_ALL_MATCHES_STATUS_OPTIONS.map((option) => {
          return (
            <StyledMenuItem key={option.id} onClick={() => handleIsActiveTournamentLevelUpdate(option)}>
              {option.id === 1 && <CheckCircle color={'success'} fontSize="small" sx={styles.menuMuiIcon} />}
              {option.id === 0 && <RemoveCircle color={'error'} fontSize="small" sx={styles.menuMuiIcon} />}
              {option.name}
            </StyledMenuItem>
          );
        })}
      </Box>
    </Menu>
  );
};
export default ManageMarketMenu;
