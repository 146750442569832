import { Box, Theme, Tooltip } from '@mui/material';
import LiabilityMonitoringTooltip from './LiabilityMonitoringTooltip';

type Props = {
  marketName: string;
  maxLiability: string;
  currentLiability: string;
  percentageValue: string;
  outcomes: { name: string; value: string }[];
};

const styles = {
  container: {
    display: 'flex',
    '& > :first-of-type': {
      borderLeft: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    },
  },
  item: {
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    borderRight: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,
    fontSize: 14,
    width: '100%',
    padding: '0 5px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  outcomesItem: {
    minWidth: 60,
    minHeight: 50,
    borderBottom: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    borderRight: (theme: Theme) => `1px solid ${theme.palette.primary.container}`,
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px',
    fontSize: 14,
    justifyContent: 'center',
  },
  popper: { '.MuiTooltip-tooltip': { maxWidth: 500 } },
};

const LiabilityEventDetailsRow = ({ marketName, maxLiability, currentLiability, percentageValue, outcomes }: Props) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.item}>{marketName}</Box>
      <Box sx={styles.item}>{maxLiability}</Box>
      <Tooltip
        PopperProps={{
          sx: styles.popper,
        }}
        title={<LiabilityMonitoringTooltip outcomes={outcomes} />}
        placement="top"
      >
        <Box sx={styles.item}>{currentLiability}</Box>
      </Tooltip>
      <Box sx={styles.outcomesItem}>{percentageValue}</Box>
    </Box>
  );
};

export default LiabilityEventDetailsRow;
