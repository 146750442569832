import { Outlet } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import { ModalProvider } from '../contexts/ModalContext';

const Root = () => {
  return (
    <MainLayout>
      <ModalProvider>
        <Outlet />
      </ModalProvider>
    </MainLayout>
  );
};

export default Root;
