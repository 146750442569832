import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';
import React from 'react';
import PasswordField from './PasswordField';
import { CustomerFormData } from '../../schema';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import the E
import { UseFormRegister, FieldErrors } from 'react-hook-form';

type PasswordFieldsProps = {
  resetPassword: boolean;
  errors: FieldErrors<CustomerFormData>;
  register: UseFormRegister<CustomerFormData>;
};

const PasswordFields: React.FC<PasswordFieldsProps> = ({ resetPassword, errors, register }) => {
  const getPasswordFieldProps = (fieldType: 'password' | 'confirmPassword') => ({
    register,
    error: errors[fieldType],
    required: !resetPassword,
  });

  return resetPassword ? (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>Reset Password</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
        <PasswordField name="password" label="Password" {...getPasswordFieldProps('password')} sx={{ mb: '1rem' }} />
        <PasswordField name="confirmPassword" label="Confirm password" {...getPasswordFieldProps('confirmPassword')} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <>
      <PasswordField name="password" label="Password" {...getPasswordFieldProps('password')} />
      <PasswordField name="confirmPassword" label="Confirm password" {...getPasswordFieldProps('confirmPassword')} />
    </>
  );
};

export default PasswordFields;
