import { zodResolver } from '@hookform/resolvers/zod';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, TextField, Theme, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CREATE_MESSAGE_USERS_OPTIONS_MAP, QUERY_KEYS } from '../../constants';
import { getAgentCredentials } from '../../utils/api';
import FormSelect from '../molecules/FormSelect';
import { CreateMessageFormData, messagesFormSchema } from '../../schema';
import { Partner, UserRole } from '../../@types/api';
import useMutateData from '../../hooks/useMutateData';
import { useEffect, useState } from 'react';
import FormAutocomplete from '../organisms/FormAutocomplete';

const makeStyles = (role: UserRole | null) => ({
  container: {
    padding: '24px',
    width: '420px',
    height: role === 'Agent' ? '500px' : '650px',
    backgroundColor: 'neutral.800',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  back: {
    display: 'flex',
    gap: '0 8px',
    cursor: 'pointer',
  },
  newMessageText: {
    color: (theme: Theme) => theme.palette.text.primary,
  },
  label: {
    fontSize: '14px',
  },
  input: {
    border: (theme: Theme) => `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    '& .MuiSvgIcon-root': {
      color: 'neutral.400',
    },
  },
  btn: { alignSelf: 'flex-end', width: '80px' },
});

const DEFAULT_FORM_DATA = {
  group: null,
  topic: '',
  text: '',
  partnerIds: [],
  playerIds: [],
};

type Props = {
  closeModal: () => void;
};

const CreateMessage = ({ closeModal }: Props) => {
  const [userRole, setUserRole] = useState<UserRole | null>(null);
  const { register, handleSubmit, control } = useForm<CreateMessageFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(messagesFormSchema),
  });
  const { createData } = useMutateData('messages', [QUERY_KEYS.sentMessages]);

  const onSubmit = (data: CreateMessageFormData) => {
    createData(data);
    closeModal();
  };

  const styles = makeStyles(userRole);

  const options = userRole && CREATE_MESSAGE_USERS_OPTIONS_MAP[userRole];

  useEffect(() => {
    const handleUserRole = async () => {
      const user = await getAgentCredentials();
      if (user) {
        setUserRole(user.role);
      }
    };
    handleUserRole();
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h4" sx={styles.newMessageText}>
          New Message
        </Typography>
        <Box sx={styles.back} onClick={closeModal}>
          <ArrowBackIcon />
          <Typography>Back</Typography>
        </Box>
      </Box>
      {userRole !== 'Agent' && (
        <>
          <Typography sx={styles.label}>To Partners</Typography>
          <FormAutocomplete<Partner, CreateMessageFormData>
            control={control}
            getOptionLabel={(options, value) => {
              return options.find((option) => option.userId === value)?.fullName || '';
            }}
            name="partnerIds"
            queryKey={[QUERY_KEYS.partners]}
            url="agent/search"
            apiService="crm"
            multiple
            optionsFilter="userId"
          />
        </>
      )}
      <Typography sx={styles.label}>To Players</Typography>
      <FormAutocomplete<Partner, CreateMessageFormData>
        control={control}
        getOptionLabel={(options, value) => {
          return options.find((option) => option.userId === value)?.fullName || '';
        }}
        name="playerIds"
        queryKey={[QUERY_KEYS.players]}
        url="customer/search"
        apiService="crm"
        multiple
        optionsFilter="userId"
      />
      {options && userRole !== 'Agent' && (
        <>
          <Typography sx={styles.label}>To group</Typography>
          <FormSelect sx={styles.input} name="group" control={control} options={options} />
        </>
      )}
      <Typography sx={styles.label}>Subject</Typography>
      <TextField sx={styles.input} fullWidth {...register('topic')} />
      <Typography sx={styles.label}>Message</Typography>
      <TextField sx={styles.input} multiline fullWidth {...register('text')} />
      <Button sx={styles.btn} onClick={handleSubmit(onSubmit)}>
        Send
      </Button>
    </Box>
  );
};

export default CreateMessage;
