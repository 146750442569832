import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { FormLayoutProps } from './FormModalLayout';

type Props = FormLayoutProps & {
  onReset?: () => void;
};

const FilterDialogLayout = ({ onSave, children, label, onClose, onReset }: Props) => {
  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Filter {label}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>{children}</Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterDialogLayout;
