import { SVGProps } from 'react';

const FeaturedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M12 23.25c6.213 0 11.25-5.037 11.25-11.25S18.213.75 12 .75.75 5.787.75 12 5.787 23.25 12 23.25Z"
        fill="#2B7FFE"
      />
      <path d="M8.137 6.563H15.9v1.912h-5.513v2.513h4.838v1.875h-4.838v4.575h-2.25V6.563Z" fill="#fff" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default FeaturedIcon;
