import { Box, CircularProgress, Dialog, IconButton, Typography } from '@mui/material';
import { DataGrid, type GridColDef, type GridRenderCellParams, type GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState } from 'react';
import type { Bet, BettingSlipWithMonitoringInfo } from '../../@types/api';

import { CheckCircle, Close, SettingsOutlined } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { startCase, upperCase } from 'lodash-es';
import { StyleObj } from '../../@types';
import { BETTING_TYPES, QUERY_KEYS } from '../../constants';
import { useModal } from '../../contexts/ModalContext';
import { defaultColumnsTicketDetails } from '../../helpers/table';
import useVisibleColumns from '../../hooks/useVisibleColumns';
import { createColumn, getBetStatusColor, getBettingSlipStatusColor } from '../../utils';
import { getData } from '../../utils/api';
import IconRenderer from '../atoms/IconRenderer';
import TicketDetailsInfoItem from '../atoms/TicketDetailsInfoItem';
import { BettingSlipFromIcon } from '../icons';
import CustomizeTable from '../molecules/CustomizeTable';
import { formatScore, getNameToDisplay } from '../../helpers';

const styles: StyleObj = {
  table: {
    border: 'none',
    '--unstable_DataGrid-radius': '0',
    '& .MuiDataGrid-withBorderColor': {
      border: 'none',
    },
    '& .MuiDataGrid-columnHeader': {
      border: `1px solid rgba(0, 83, 55, 0.20)`,
      backgroundColor: 'background.lightGreen',
      '&:not(:first-of-type)': {
        borderLeft: 'none',
      },
      '&:last-of-type': {
        width: '100% !important',
        maxWidth: 'unset !important',
      },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      '> div': {
        width: '100%',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: 'background.light',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
  generalInfoRow: {
    backgroundColor: 'primary.main',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    py: 0.5,
  },
  generalInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
    px: 2,
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'background.lighter',
    p: 1.5,
  },
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      borderRadius: 2,
      boxShadow: 0,
    },
  },
  closeBtn: {
    color: 'white',
    pr: 2,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  loader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    py: 6,
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
};

type TicketDetailsModalProps = {
  id: string;
  onClose: () => void;
};

const getTicketBettingType = (ticketDetails: BettingSlipWithMonitoringInfo | undefined) => {
  const systemBettingType =
    ticketDetails?.requiredHitCount && ticketDetails.bettingSlipType === 'System'
      ? ` / ${BETTING_TYPES[ticketDetails.requiredHitCount - 1]}`
      : '';

  return `${ticketDetails?.bettingSlipType}${systemBettingType}`;
};

const TicketDetailsModal = ({ id, onClose }: TicketDetailsModalProps) => {
  const [open, setOpen] = useState(true);
  const { openModal } = useModal();

  const { data: ticketDetails, isLoading } = useQuery([QUERY_KEYS.bettingSlips, id], {
    queryFn: (): Promise<BettingSlipWithMonitoringInfo> => getData(`/betting-slips/${id}`),
    enabled: !!id,
  });

  const reofferedFromId = ticketDetails?.reofferedFromId;

  const { data: reofferedFromTicketDetails } = useQuery([QUERY_KEYS.bettingSlips, reofferedFromId], {
    queryFn: (): Promise<BettingSlipWithMonitoringInfo> => getData(`/betting-slips/${reofferedFromId}`),
    enabled: !!reofferedFromId,
  });

  const handleClose = (_event: object, reason: string) => {
    if (reason === 'backdropClick') return;

    setOpen(false);
    onClose();
  };

  const columns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('startDate', 'Date and Time', {
      valueGetter: (params: GridValueGetterParams<Bet>) => {
        return dayjs(params.row.event.startDate).format('DD-MM-YYYY HH:mm');
      },
      alwaysVisible: true,
      sortable: false,
      flex: 1.25,
    }),
    createColumn('competitionName', 'Competition', {
      renderCell: (params: GridRenderCellParams<Bet>) => {
        return params.row.competitionName;
      },
      sortable: false,
      flex: 1.5,
    }),
    createColumn('tournamentName', 'Tournament', {
      renderCell: (params: GridRenderCellParams<Bet>) => {
        return params.row.tournamentName;
      },
      sortable: false,
      flex: 3.75,
    }),
    createColumn('icon', 'Icon', {
      renderCell: (params: GridRenderCellParams<Bet>) => {
        return <IconRenderer name={params.row.icon} type="sport" />;
      },
      sortable: false,
      alwaysVisible: true,
      flex: 0.75,
    }),
    createColumn('from', 'From', {
      renderCell: (params: GridRenderCellParams<Bet>) => {
        const from = params.row.isLive ? 'In-play' : 'Pre-match';
        return <BettingSlipFromIcon from={from} />;
      },
      sortable: false,
      flex: 0.75,
    }),
    createColumn('banker', 'Banker', {
      renderCell: (params: GridRenderCellParams<Bet>) => {
        return params.row.banker ? <CheckCircle color="success" /> : '';
      },
      sortable: false,
      flex: 0.75,
    }),
    createColumn('event', 'Event', {
      valueGetter: (params: GridValueGetterParams<Bet>) => {
        return params.row.event.name;
      },
      alwaysVisible: true,
      sortable: false,
      flex: 2.5,
    }),
    createColumn('market', 'Market', {
      valueGetter: (params: GridValueGetterParams<Bet>) => {
        return getNameToDisplay(params.row.market);
      },
      alwaysVisible: true,
      sortable: false,
      flex: 2,
    }),
    createColumn('specialValue', 'SV', {
      valueGetter: (params: GridValueGetterParams<Bet>) => {
        return params.row.specialValues.map((sv) => sv.value).join('& ');
      },
      sortable: false,
    }),
    createColumn('outcome', 'Outcome', {
      valueGetter: (params: GridValueGetterParams<Bet>) => {
        return getNameToDisplay(params.row.outcome);
      },
      alwaysVisible: true,
      sortable: false,
    }),
    createColumn('isWays', 'Ways', {
      renderCell: (params: GridRenderCellParams<Bet>) => {
        return params.row.ways ? <CheckCircle color="success" /> : '';
      },
      sortable: false,
      flex: 0.75,
    }),
    createColumn('odds', 'Odds', {
      sortable: false,
      alwaysVisible: true,
      renderCell: (params: GridRenderCellParams<Bet>) => {
        const reofferedTicketBet = reofferedFromTicketDetails?.bets.find(
          (bet) =>
            bet.outcome.outcomeTypeId === params.row.outcome.outcomeTypeId && bet.event.id === params.row.event.id
        );
        return (
          <TicketDetailsInfoItem oldValue={reofferedTicketBet?.odds} newValue={params.value} textVariant="body2" />
        );
      },
    }),
    createColumn('status', 'Status', {
      renderCell: (params: GridRenderCellParams<Bet>) => {
        return (
          <Typography variant="h5" color={getBetStatusColor(params.row.status)}>
            {startCase(params.value)}
          </Typography>
        );
      },
      sortable: false,
    }),
    createColumn('result', 'Result', {
      valueGetter: (params: GridValueGetterParams<Bet>) => formatScore(params.row.mainEventPartScore),
      sortable: false,
    }),
  ];

  const { visibleColumns, setVisibleColumns, visibleColumnsLSKey } = useVisibleColumns({
    manualLSKey: 'ticket-monitoring-details-table', // Used to differentiate tables on same path
    columns,
    defaultVisibleColumns: defaultColumnsTicketDetails,
  });

  const onCloseButtonClick = () => {
    setOpen(false);
    onClose();
  };

  const openCustomizeTableModal = () => {
    openModal(
      <CustomizeTable
        columns={columns}
        defaultVisibleColumns={defaultColumnsTicketDetails}
        setVisibleColumns={setVisibleColumns}
        visibleColumns={visibleColumns}
        visibleColumnsLSKey={visibleColumnsLSKey}
      />
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={styles.dialog} fullWidth maxWidth="xl">
      {isLoading ? (
        <Box sx={styles.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={styles.generalInfoRow}>
            {ticketDetails && (
              <Box sx={styles.generalInfoWrapper}>
                <Typography variant="h5">Username: {ticketDetails.user?.username}</Typography>
                <Typography variant="h5">Type: {getTicketBettingType(ticketDetails)}</Typography>
                <Typography variant="h5">From: {ticketDetails.from}</Typography>
                {ticketDetails.operator && (
                  <Typography variant="h5">Betting Operator: {ticketDetails.operator.fullName}</Typography>
                )}
              </Box>
            )}
            <Box>
              <IconButton onClick={openCustomizeTableModal} color="inherit" size="small">
                <SettingsOutlined />
              </IconButton>
              <IconButton onClick={onCloseButtonClick} sx={styles.closeBtn}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box sx={styles.infoRow}>
            <TicketDetailsInfoItem
              label="Stake"
              oldValue={reofferedFromTicketDetails?.totalStakeAmount.toFixed(2)}
              newValue={ticketDetails?.totalStakeAmount.toFixed(2)}
            />
            <TicketDetailsInfoItem
              label="Odds"
              oldValue={reofferedFromTicketDetails?.totalOdds}
              newValue={ticketDetails?.totalOdds}
            />
            <TicketDetailsInfoItem
              label="Combinations"
              oldValue={reofferedFromTicketDetails?.combinationsCount}
              newValue={ticketDetails?.combinationsCount}
            />
            <TicketDetailsInfoItem
              label="Possible Winnings"
              oldValue={reofferedFromTicketDetails?.possibleWinnings.toFixed(2)}
              newValue={ticketDetails?.possibleWinnings.toFixed(2)}
            />
            <TicketDetailsInfoItem
              label="Winnings"
              oldValue={reofferedFromTicketDetails?.winnings?.toFixed(2)}
              newValue={ticketDetails?.winnings?.toFixed(2)}
            />
            <Typography variant="h5" color={getBettingSlipStatusColor(ticketDetails?.status || 'open')}>
              {upperCase(ticketDetails?.status)}
            </Typography>
          </Box>
          <DataGrid
            rows={ticketDetails?.bets || []}
            loading={isLoading}
            columns={columns}
            hideFooterPagination
            columnVisibilityModel={visibleColumns}
            disableColumnMenu
            showColumnVerticalBorder
            sx={styles.table}
          />
        </>
      )}
    </Dialog>
  );
};

export default TicketDetailsModal;
