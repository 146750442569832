import { SVGProps } from 'react';
import { BetStatus } from '../../@types/api';
import { RESOLVE_STATUS_COLORS } from '../../constants';

type BetStatusIconProps = SVGProps<SVGSVGElement> & {
  status: BetStatus | 'deleted';
};

const BetStatusIcon = (props: BetStatusIconProps) => {
  const { status } = props;

  const fill = RESOLVE_STATUS_COLORS[status];
  const isHalf = Array.isArray(fill);

  return (
    <svg width="24" height="24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z"
        fill={isHalf ? fill?.[0] : fill}
      />

      {isHalf && (
        <>
          <g clipPath={`url(#${status})`}>
            <path fillRule="evenodd" clipRule="evenodd" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" fill={fill?.[1]} />
          </g>
          <defs>
            <clipPath id={status}>
              <path fill="#fff" transform="translate(12 4)" d="M0 0h8v16H0z" />
            </clipPath>
          </defs>
        </>
      )}
    </svg>
  );
};

export default BetStatusIcon;
