import { useParams } from 'react-router-dom';
import { PATH_TO_USER_TYPE_MAP } from '../../../../config/crm';
import { CUSTOMER_WALLET_TABS } from '../../../../constants';
import WalletLayoutPage from './WalletLayoutPage';

const CustomerWalletLayoutPage = () => {
  const { userType, userId } = useParams();

  return (
    userId && (
      <WalletLayoutPage
        userId={userId}
        userType={PATH_TO_USER_TYPE_MAP[userType as string]}
        tabs={CUSTOMER_WALLET_TABS}
        buttonLabel="Add/Remove Credit"
        form="handle-credits"
      />
    )
  );
};

export default CustomerWalletLayoutPage;
