import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DialogType } from '../../../../@types';
import { ConditionEntityType, ConditionGroup } from '../../../../@types/api';
import { CONDITION_ENTITY_SELECT_OPTIONS, QUERY_KEYS } from '../../../../constants';
import { useModal } from '../../../../contexts/ModalContext';
import useMutateData from '../../../../hooks/useMutateData';
import { ConditionGroupsFormData, conditionGroupSchema } from '../../../../schema';
import FormModalLayout from '../../../layouts/FormModalLayout';
import FormSelect from '../../../molecules/FormSelect';

const DEFAULT_FORM_DATA = {
  name: '',
  type: 'sport' as ConditionEntityType,
  description: '',
};

type Props = DialogType & {
  item: ConditionGroup;
};

const ConditionGroupForm = ({ closeModal }: Props) => {
  const { item } = useModal<ConditionGroup>();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ConditionGroupsFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(conditionGroupSchema),
  });

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        type: item.modelType,
      });
    }
  }, [item, reset]);

  const { createData: createConditionGroup, updateData: updateConditionGroup } = useMutateData('condition-groups', [
    QUERY_KEYS.conditionGroups,
  ]);

  const handleClose = () => {
    closeModal?.();
    reset(DEFAULT_FORM_DATA);
  };

  const onFormSubmit = (payload: ConditionGroupsFormData) => {
    if (item) {
      updateConditionGroup(item.id, payload, () => {
        handleClose();
      });
    } else {
      createConditionGroup(payload, () => {
        handleClose();
      });
    }
  };

  return (
    <FormModalLayout onSave={handleSubmit(onFormSubmit)} label={item ? 'Edit' : 'Add new'} onClose={handleClose}>
      <TextField error={!!errors.name} helperText={errors.name?.message} label="Name" {...register('name')} />
      <TextField
        error={!!errors.description}
        helperText={errors.description?.message}
        label="Description"
        multiline
        {...register('description')}
      />
      <FormSelect
        label="Entity"
        name="type"
        control={control}
        options={CONDITION_ENTITY_SELECT_OPTIONS}
        disabled={!!item}
      />
    </FormModalLayout>
  );
};

export default ConditionGroupForm;
