import { Control, FieldError, FieldValues, Merge, Path } from 'react-hook-form';
import { ParticipantsQueryParams } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import FormAutocomplete from '../organisms/FormAutocomplete';
import { isEmpty } from 'lodash-es';

type ParticipantSelectProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  required?: boolean;
  queryParams?: ParticipantsQueryParams;
  error?: Merge<FieldError, (FieldError | undefined)[]>;
  multiple?: boolean;
};

const ParticipantSelect = <T extends FieldValues>({
  control,
  name,
  label,
  required,
  queryParams,
  error,
  multiple,
}: ParticipantSelectProps<T>) => {
  return (
    <FormAutocomplete
      name={name}
      control={control}
      label={label ?? 'Participant'}
      required={required}
      url="participants"
      queryKey={[QUERY_KEYS.participants, queryParams]}
      queryParams={{
        ...queryParams,
        isActive: true,
      }}
      disabled={isEmpty(queryParams?.tournamentId) && isEmpty(queryParams?.tournamentIds)}
      error={error}
      multiple={multiple}
      hookEnabled={!!queryParams}
      getOptionLabel={(options, value) => {
        const option = options.find((option) => option?.id === value);
        return option?.name || '';
      }}
    />
  );
};

export default ParticipantSelect;
