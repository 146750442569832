import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Tab, Tabs, TabsProps } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StyleObj } from '../../../@types';
import { CRMTabConfig } from '../../../config/crm';
import { useCRMUser } from '../../../contexts/CRMUserContext';
import TabPanel from '../../atoms/TabPanel';

const styles: StyleObj = {
  drawer: {
    width: 440,
  },
  drawerContainer: {
    '& .MuiDrawer-paper': {
      mt: 8,
      background: (theme) => theme.palette.background.light,
      boxShadow: 2,
    },
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tabPanelWrapper: {
    p: 2,
  },
  icon: {
    mt: 1,
    ':hover': {
      background: 'transparent',
    },
  },
};

type StyledTabProps = {
  label: string;
};

const StyledTabs = styled((props: TabsProps) => <Tabs {...props} />)({
  width: '100%',
  padding: '14px 12px 0 0',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
});

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  ...theme.typography.h5,
  textTransform: 'none',
  minHeight: 32,
  padding: '6px 8px',
  '&.Mui-selected': {
    borderRadius: '6px',
    background: theme.palette.primary.container,
  },
}));

type Props = {
  config: CRMTabConfig;
};

const CRMDetailsDrawer = ({ config }: Props) => {
  const [activeTab, setActiveTab] = useState(0);

  const { selectedCRMUser, setSelectedCRMUser } = useCRMUser();

  const location = useLocation();

  // Close drawer when switching between partners and players tabs
  useEffect(() => {
    setSelectedCRMUser(null);
  }, [location.pathname, setSelectedCRMUser]);

  const closeDetailsDrawer = () => {
    setSelectedCRMUser(null);
  };

  const handleTabChange = (_event: React.SyntheticEvent, selectedTab: number) => {
    setActiveTab(selectedTab);
  };

  // Specify visibleFor array in config if a certain tab should only be visible for specific selected user roles
  const tabsByRole = !!selectedCRMUser
    ? config.filter((tab) => !tab.visibleFor || tab.visibleFor.includes(selectedCRMUser.role?.name))
    : [];

  useEffect(() => {
    setActiveTab(0);
  }, [selectedCRMUser?.id]);

  return (
    <Drawer
      anchor="right"
      open={!!selectedCRMUser}
      onClose={closeDetailsDrawer}
      hideBackdrop
      ModalProps={{
        slotProps: {
          root: { style: { position: 'absolute', right: 'unset' } },
        },
      }}
      sx={styles.drawerContainer}
    >
      <Box sx={styles.drawer}>
        <Box sx={styles.tabsWrapper}>
          <IconButton onClick={closeDetailsDrawer} color="primary" sx={styles.icon}>
            <ChevronRightIcon />
          </IconButton>
          <StyledTabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            sx={styles.tabs}
            variant="fullWidth"
          >
            {tabsByRole.map((tab) => (
              <StyledTab key={tab.label} label={tab.label} />
            ))}
          </StyledTabs>
        </Box>
        {tabsByRole.map((tab, index) => (
          <TabPanel key={tab.label} value={activeTab} index={index}>
            <Box sx={styles.tabPanelWrapper}>{!!selectedCRMUser && tab.Component}</Box>
          </TabPanel>
        ))}
      </Box>
    </Drawer>
  );
};

export default CRMDetailsDrawer;
