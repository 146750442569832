import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import { LS_KEYS } from '../../../constants';
import { JACKPOT_PRODUCT_OPTIONS, JACKPOT_TYPE_OPTIONS } from '../../../constants/jackpot';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import FormSelect from '../../molecules/FormSelect';

const DEFAULT_FORM_DATA = {
  type: undefined,
  product: undefined,
};

type JackpotReportsFormData = {
  type: string;
  product: string;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterJackpotReports = ({ closeModal, changeQuery }: Props) => {
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const { pathname } = useLocation();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;

  const getSavedData = () => getPersistData<JackpotReportsFormData>(formDataKey) || DEFAULT_FORM_DATA;

  const { control, handleSubmit } = useForm<JackpotReportsFormData>({
    defaultValues: getSavedData(),
  });

  const onFormSubmit = (data: JackpotReportsFormData) => {
    setPersistData<JackpotReportsFormData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  const handleResetFilter = () => {
    deletePersistData(formDataKey);
    changeQuery(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  return (
    <FilterDialogLayout
      label=""
      onClose={() => {
        closeModal?.();
      }}
      onSave={handleSubmit(onFormSubmit)}
      onReset={handleResetFilter}
    >
      <FormSelect name="product" label="Product" control={control} options={JACKPOT_PRODUCT_OPTIONS} />
      <FormSelect name="type" label="Type" control={control} options={JACKPOT_TYPE_OPTIONS} />
    </FilterDialogLayout>
  );
};

export default FilterJackpotReports;
