import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MarketGroupsList, MarketType, PaginatedData, Sport } from '../../../@types/api';
import { DISPLAY_SELECT_OPTIONS, MARKET_TYPE_USED_FOR_OPTIONS, QUERY_KEYS } from '../../../constants';
import useMutateData from '../../../hooks/useMutateData';

import { DialogType } from '../../../@types';
import { useModal } from '../../../contexts/ModalContext';
import { MarketGroupsFormData, marketGroupsSchema } from '../../../schema';
import { getData } from '../../../utils/api';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';
import { isArray } from 'lodash-es';

const DEFAULT_FORM_DATA = {
  name: '',
  usedFor: '',
  displayType: '',
  marketTypeIds: [],
  isActive: false,
  sportId: '',
  id: '',
};

type Props = DialogType;

const MarketGroupForm = ({ closeModal }: Props) => {
  const { item } = useModal<MarketGroupsList>();
  const { createData, updateData } = useMutateData('market-type-groups', [QUERY_KEYS.marketTypeGroups]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm<MarketGroupsFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(marketGroupsSchema),
  });

  const sportId = watch('sportId');
  const usedFor = watch('usedFor');
  const marketTypeIds = watch('marketTypeIds');

  const { data: sportsData } = useQuery([QUERY_KEYS.sports], {
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        limit: 100,
        page: 1,
      }),
  });

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        sportId: item.sportId,
        name: item.name,
        usedFor: item.usedFor,
        displayType: item.displayType,
        marketTypeIds: item.marketTypes.map((market) => market.id),
      });
    }
  }, [item, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId' || name === 'usedFor') {
        setValue('marketTypeIds', []);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleClose = () => {
    reset(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: MarketGroupsFormData) => {
    if (item) {
      updateData(item.id, data, () => {
        handleClose();
      });
    } else {
      createData(data, () => {
        handleClose();
      });
    }
  };

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={item ? `Edit market group` : 'Add new market group'}
      onClose={handleClose}
      isEdit={!!item}
    >
      <TextField error={!!errors.name} helperText={errors.name?.message} label="Name" {...register('name')} required />
      <FormSelect
        label="Sport"
        name="sportId"
        required
        control={control}
        error={errors.sportId}
        options={sportsData?.items || []}
        disabled={!!item}
      />
      <FormSelect
        label="Used for"
        name="usedFor"
        required
        control={control}
        error={errors.usedFor}
        options={MARKET_TYPE_USED_FOR_OPTIONS}
        disabled={!!item}
      />
      <FormSelect
        label="Display"
        name="displayType"
        required
        error={errors.displayType}
        control={control}
        options={DISPLAY_SELECT_OPTIONS}
        disabled={!!item}
        closeOnSelect
      />
      <FormFieldStack label="Status">
        <Controller name="isActive" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <FormAutocomplete<MarketType, MarketGroupsFormData>
        name="marketTypeIds"
        control={control}
        label="Markets"
        multiple
        url="market-types"
        queryKey={[QUERY_KEYS.marketTypes, sportId, usedFor, marketTypeIds]}
        queryParams={{
          sportId,
          isActive: true,
          usedFor,
          marketTypeIds: isArray(marketTypeIds) ? marketTypeIds : [marketTypeIds],
        }}
        disabled={!sportId || !usedFor}
        hookEnabled={!!sportId && !!usedFor}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option ? `${option?.name}, ${option?.eventPartName || ''}` : '';
        }}
      />
    </FormModalLayout>
  );
};

export default MarketGroupForm;
