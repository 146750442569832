import type { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import type { Participant } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { createEditColumn, createIsActiveColumn, createSportIconColumn } from '../../helpers/columns';
import { defaultColumnsParticipants } from '../../helpers/table';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import useSort from '../../hooks/useSort';
import { createColumn } from '../../utils';
import TableTemplate from '../templates/TableTemplate';

const ParticipantsPage = () => {
  const {
    data: participantsData,
    updateQueryParams,
    isFetching,
    isLoading,
    changeQuery,
  } = usePagination<Participant>('participants');
  const { deleteData, updateData } = useMutateData('participants', [QUERY_KEYS.participants]);
  const { handleSort } = useSort(changeQuery);

  const columns: GridColDef[] = [
    createColumn('id', 'ID', {
      sortable: false,
    }),
    createColumn('name', 'Participant Name'),
    createColumn('shortName', 'Participant Short Name'),
    createColumn('sportName', 'Sport', { valueGetter: (params) => params.row.sport.name }),
    createSportIconColumn<Participant>(),
    createColumn('competitionName', 'Competitions', {
      valueGetter: (params: GridValueGetterParams<Participant>) =>
        params.row.competitions.map((competition) => competition.name).join(', '),
    }),
    createColumn('tournamentName', 'Tournaments', {
      valueGetter: (params: GridValueGetterParams<Participant>) =>
        params.row.tournaments.map((tournament) => tournament.name).join(', '),
    }),
    createIsActiveColumn<Participant>({
      updateData,
    }),
    createEditColumn<Participant>({
      deleteData,
    }),
  ];

  const handleSearch = (value: string | null) => {
    changeQuery({ search: value });
  };

  return (
    <TableTemplate
      rows={participantsData?.items || []}
      columns={columns}
      rowCount={participantsData?.count || 0}
      loading={isFetching || isLoading}
      defaultVisibleColumns={defaultColumnsParticipants}
      createItemBtnLabel="Add new participant"
      handlePaginationModelChange={updateQueryParams}
      handleSearch={handleSearch}
      handleSort={handleSort}
      changeQuery={changeQuery}
      formType="participants"
      showExport
    />
  );
};

export default ParticipantsPage;
