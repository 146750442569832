import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

const styles = {
  width: 130,
};

type Props = {
  options: {
    name: string;
    id: number;
  }[];
  value: number | false;
  handleRefetchIntervalChange: (e: SelectChangeEvent<number | false>) => void;
};

const SelectRefreshInterval = ({ options, value, handleRefetchIntervalChange }: Props) => {
  return (
    <>
      <InputLabel id="refetch-interval-select-label">Refresh Interval</InputLabel>
      <Select
        value={value}
        label="Refresh Interval"
        onChange={handleRefetchIntervalChange}
        labelId="refetch-interval-select-label"
        id="refetch-interval-select"
        sx={styles}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SelectRefreshInterval;
