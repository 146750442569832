import { SvgIconComponent } from '@mui/icons-material';
import { Box, Button, ButtonProps } from '@mui/material';

const styles = {
  button: {
    pl: 0,
  },
  iconWrapper: {
    fill: '#fff',
  },
  icon: {
    backgroundColor: 'primary.main',
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    display: 'grid',
    placeItems: 'center',
  },
};

type TableActionButtonProps = ButtonProps & {
  Icon: SvgIconComponent;
};
const TableActionButton = ({ Icon, children, ...buttonProps }: TableActionButtonProps) => {
  return (
    <Button
      sx={styles.button}
      variant="text"
      startIcon={
        <Box sx={styles.icon}>
          <Icon sx={styles.iconWrapper} />
        </Box>
      }
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default TableActionButton;
