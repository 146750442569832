import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { useState } from 'react';
import { DateRangeOption, StyleObj } from '../../@types';
import { PREDEFINED_DATE_RANGE_OPTIONS } from '../../constants';
import { generateDateRangeTimestamps } from '../../helpers';

const styles: StyleObj = {
  dateRangeWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    position: 'absolute',
    top: -6,
    right: 0,
  },
  datePickersWrapper: {
    display: 'flex',
    gap: 2,
  },
  select: {
    width: 200,
  },
};

export type DayjsTimestamp = { fromTimestamp: Dayjs; toTimestamp: Dayjs };

type Props = {
  date: DayjsTimestamp;
  onDateChange: (date: DayjsTimestamp) => void;
};

const DateRangeSelect = ({ date, onDateChange }: Props) => {
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState<DateRangeOption>(
    PREDEFINED_DATE_RANGE_OPTIONS[0].id
  );

  const handleDateChange = (key: 'fromTimestamp' | 'toTimestamp', value: Dayjs | null) => {
    if (!value) return;

    onDateChange({ ...date, [key]: value });
  };

  const handleDateRangeChange = (event: SelectChangeEvent) => {
    setSelectedDateRangeOption(event.target.value as DateRangeOption);

    const dateRange = generateDateRangeTimestamps(event.target.value as DateRangeOption);
    onDateChange(dateRange as DayjsTimestamp);
  };

  return (
    <Box sx={styles.dateRangeWrapper}>
      <Box sx={styles.datePickersWrapper}>
        <DateTimePicker
          label="From"
          value={date.fromTimestamp}
          onChange={(value) => handleDateChange('fromTimestamp', value)}
          ampm={false}
        />
        <DateTimePicker
          label="To"
          value={date.toTimestamp}
          onChange={(value) => handleDateChange('toTimestamp', value)}
          ampm={false}
          minDate={date.fromTimestamp}
        />
      </Box>
      <FormControl>
        <InputLabel>Predefined</InputLabel>
        <Select value={selectedDateRangeOption} label="Predefined" onChange={handleDateRangeChange} sx={styles.select}>
          {PREDEFINED_DATE_RANGE_OPTIONS.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DateRangeSelect;
