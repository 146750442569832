import { Replay } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import {
  BettingSlipAcceptStatus,
  BettingSlipFrom,
  BettingSlipStatus,
  BettingSlipType,
  Customer,
  Device,
  Partner,
} from '../../../@types/api';
import {
  BETTING_SLIP_ACCEPT_STATUS_OPTIONS,
  BETTING_SLIP_AUTHORIZED_TICKETS_OPTIONS,
  BETTING_SLIP_FROM_OPTIONS,
  BETTING_SLIP_IS_WAYS_OPTIONS,
  BETTING_SLIP_REOFFERED_OPTIONS,
  BETTING_SLIP_STATUS_OPTIONS,
  BETTING_SLIP_TYPE_OPTIONS,
  DEVICE_OPTIONS,
  LS_KEYS,
  QUERY_KEYS,
} from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import { prepareBoolValue } from '../../../utils';
import FormSelect from '../../molecules/FormSelect';
import FormAutocomplete from '../FormAutocomplete';

const defaultValues = {
  from: undefined,
  bettingSlipType: undefined,
  acceptStatus: undefined,
  resolveStatuses: [],
  device: undefined,
  isWays: undefined,
  cashOut: undefined,
  riskFactor: undefined,
  reoffered: undefined,
  authorizedTickets: undefined,
  userId: undefined,
  operatorId: undefined,
};

export type FilterTicketMonitoringData = {
  from?: BettingSlipFrom;
  bettingSlipType?: BettingSlipType;
  acceptStatus?: BettingSlipAcceptStatus;
  resolveStatuses?: BettingSlipStatus[];
  device?: Device;
  isWays?: boolean;
  cashOut?: boolean;
  riskFactor?: string;
  reoffered?: boolean;
  authorizedTickets?: string;
  userId?: string;
  operatorId?: string;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterTicketMonitoring = ({ changeQuery, closeModal }: Props) => {
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const { pathname } = useLocation();

  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const getSavedData = () => getPersistData<FilterTicketMonitoringData>(formDataKey) || defaultValues;

  const { control, handleSubmit, reset } = useForm<FilterTicketMonitoringData>({
    defaultValues: getSavedData(),
  });

  const prepareData = (data: FilterTicketMonitoringData) => {
    const preparedData = { ...data };

    preparedData.isWays = prepareBoolValue(preparedData.isWays);
    preparedData.cashOut = prepareBoolValue(preparedData.cashOut);
    preparedData.reoffered = prepareBoolValue(preparedData.reoffered);

    return preparedData;
  };

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);
    changeQuery(prepareData(defaultValues));

    closeModal?.();
  };

  const onFormSubmit = (data: FilterTicketMonitoringData) => {
    const payload = prepareData(data);

    setPersistData<FilterTicketMonitoringData>(formDataKey, data);
    changeQuery(payload);
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Filter betting slips
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormAutocomplete<Customer, FilterTicketMonitoringData>
            name="userId"
            control={control}
            queryKey={[QUERY_KEYS.customers]}
            apiService="crm"
            url="customer/search"
            label="Player"
            hookEnabled
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.userId === value);
              return option ? `${option.firstName} ${option.lastName} (${option.username})` : '';
            }}
            optionsFilter="userId"
          />
          <FormSelect control={control} name="from" label="From" options={BETTING_SLIP_FROM_OPTIONS} />
          <FormSelect
            control={control}
            name="bettingSlipType"
            label="Ticket type"
            options={BETTING_SLIP_TYPE_OPTIONS}
          />
          <FormSelect
            control={control}
            name="acceptStatus"
            label="Status"
            options={BETTING_SLIP_ACCEPT_STATUS_OPTIONS}
          />
          <FormSelect
            control={control}
            name="resolveStatuses"
            label="Resolution status"
            options={BETTING_SLIP_STATUS_OPTIONS}
            multiple
            closeOnSelect
          />
          <FormSelect control={control} name="reoffered" label="Reoffered" options={BETTING_SLIP_REOFFERED_OPTIONS} />
          <FormSelect
            control={control}
            name="authorizedTickets"
            label="Authorized tickets"
            options={BETTING_SLIP_AUTHORIZED_TICKETS_OPTIONS}
          />
          <FormAutocomplete<Partner, FilterTicketMonitoringData>
            name="operatorId"
            control={control}
            queryKey={[QUERY_KEYS.agentSearch]}
            apiService="crm"
            url="agent/search"
            label="Betting operator"
            getOptionLabel={(options, value) => {
              const option = options.find((option) => option?.id === value);
              return option ? option.fullName : '';
            }}
          />
          <FormSelect control={control} name="device" label="Ip/Source" options={DEVICE_OPTIONS} />
          <FormSelect control={control} name="isWays" label="Ways" options={BETTING_SLIP_IS_WAYS_OPTIONS} />
          <FormSelect control={control} name="cashOut" label="Cash out" options={[]} />
          <FormSelect control={control} name="riskFactor" label="Risk factor" options={[]} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleFilterReset} startIcon={<Replay />} size="small">
          Reset filters
        </Button>
        <Button variant="outlined" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onFormSubmit)}>
          Save
        </Button>
      </DialogActions>
    </>
  );
};

export default FilterTicketMonitoring;
