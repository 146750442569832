import { DialogType } from '../../@types';
import { AdditionalQueryParams } from '../../hooks/usePagination';

// FILTERS
import FilterLiabilityMonitoring from '../organisms/FilterLiabilityMonitoring';
import FilterCRMUsers from '../organisms/forms/FilterCRMUsers';
import FilterEvents from '../organisms/forms/FilterEvents';
import FilterPlayerReport from '../organisms/forms/FilterPlayerReport';
import FilterPreMatchSettingsForm from '../organisms/forms/FilterPreMatchSettingsData';
import FilterResults from '../organisms/forms/FilterResults';
import FilterSportsbookReports from '../organisms/forms/FilterSportsbookReports';
import FilterTicketAuthorization from '../organisms/forms/FilterTicketAuthorization';
import FilterTicketMonitoring from '../organisms/forms/FilterTicketMonitoring';
import FilterWalletTransactions from '../organisms/forms/FilterWalletTransactions';
import FilterPaymentReportsList from '../organisms/forms/FilterPaymentReportsList';
import FilterPaymentReportsTotal from '../organisms/forms/FilterPaymentReportsTotal';
import FilterDailyReports from '../organisms/FilterDailyReports';
import FilterAgentReport from '../organisms/FilterAgentsReports';
import FilterManagersReport from '../organisms/FilterManagersReports';
import FilterJackpots from '../organisms/forms/FilterJackpots';
import FilterJackpotReports from '../organisms/forms/FilterJackpotReports';
import FilterOutrights from '../organisms/forms/FilterOutrights';

const filters = {
  events: FilterEvents,
  outrights: FilterOutrights,
  'jackpot-report': FilterJackpotReports,
  'sportsbook-report': FilterSportsbookReports,
  'payments-list': FilterPaymentReportsList,
  'payments-total': FilterPaymentReportsTotal,
  'player-report': FilterPlayerReport,
  agents: FilterAgentReport,
  managers: FilterManagersReport,
  'events-results': FilterResults,
  '*': FilterPreMatchSettingsForm,
  'ticket-monitoring': FilterTicketMonitoring,
  'ticket-authorization': FilterTicketAuthorization,
  'liability-monitoring': FilterLiabilityMonitoring,
  crm: FilterCRMUsers,
  partners: FilterCRMUsers,
  'wallet-transaction': FilterWalletTransactions,
  'payment-transaction': FilterWalletTransactions,
  daily: FilterDailyReports,
  jackpot: FilterJackpots,
};

export type FilterTypes = keyof typeof filters;

type Props = DialogType & {
  filter: FilterTypes;
  changeQuery?: (data: AdditionalQueryParams) => void;
};

const FilterModal = ({ filter, changeQuery, closeModal = () => void 0 }: Props) => {
  const FilterComponent = filters[filter];
  // @ts-expect-error
  return <FilterComponent changeQuery={changeQuery} closeModal={closeModal} />;
};

export default FilterModal;
