import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import { LiabilityEventDetails } from '../../@types';
import LiabilityEventDataDetails from './LiabilityEventDataDetails';
import { ChevronRight } from '@mui/icons-material';

const styles = {
  container: {
    width: '50%',
  },
  header: {
    backgroundColor: 'primary.main',
    color: '#fff',
    borderRadius: '8px 8px 0 0',
    px: 1.5,
    gap: 0.5,
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    '> * ': {
      typography: 'h5',
    },
  },
  eventDetails: {
    backgroundColor: 'background.lighter',
    padding: '10px 15px',
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  text: {
    fontWeight: 700,
    fontSize: '14px',
  },
  eventDetailsText: {
    fontWeight: 600,
    fontSize: '14px',
  },
  actions: {
    height: '87px',
  },
};

type Props = {
  eventDetails: LiabilityEventDetails;
  onClose: () => void;
};

const LiabilityEvent = ({ eventDetails, onClose }: Props) => {
  const { id, eventName, startDate, competitionName, sportName, tournamentName } = eventDetails;

  const displayStartDate = dayjs(startDate).format('DD.MM.YYYY | HH:mm');

  return (
    <Box sx={styles.container}>
      <Box sx={styles.actions}></Box>
      <Box>
        <Box sx={styles.header}>
          <IconButton onClick={onClose} color="inherit" sx={{ p: 0 }}>
            <ChevronRight />
          </IconButton>
          <Typography sx={styles.text}>{`${eventName} | ${displayStartDate}`}</Typography>
        </Box>
        <Box sx={styles.eventDetails}>
          <Typography
            sx={styles.eventDetailsText}
          >{`${sportName} | ${competitionName} | ${tournamentName}`}</Typography>
        </Box>
        <LiabilityEventDataDetails eventId={id} />
      </Box>
    </Box>
  );
};

export default LiabilityEvent;
