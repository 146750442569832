import { EditOutlined } from '@mui/icons-material';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAuth } from 'neofusion-fe-shared';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NoteType, StyleObj } from '../../@types';
import { CRMApiEntityType } from '../../@types/api';
import { NOTE_TYPE_OPTIONS, QUERY_KEYS } from '../../constants';
import { useCRMUser } from '../../contexts/CRMUserContext';
import useMutateData from '../../hooks/useMutateData';
import TypeSelect from '../molecules/TypeSelect';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import { USER_TYPE_TO_CRM_API_ENTITY_MAP } from '../../config/crm';

const styles: StyleObj = {
  icon: {
    mr: 0.5,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  btnWrapper: {
    textAlign: 'right',
  },
  editTextField: {
    typography: 'h6',
    p: 1,
    background: '#fff',
  },
};

type CreateNoteInput = {
  author: string;
  customerId: string;
  comment: string;
  type: NoteType;
};

type Props = {
  userType: CRMApiEntityType;
};

const CreateNote = ({ userType }: Props) => {
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);

  const { username } = useAuth();

  const { selectedCRMUser } = useCRMUser();
  const selectedCRMUserId = selectedCRMUser?.id;

  const invalidateData = useInvalidateQuery();
  const apiEntity = USER_TYPE_TO_CRM_API_ENTITY_MAP[userType];

  const { createData, isLoading } = useMutateData(`notes/${apiEntity}`, [QUERY_KEYS.crmNotes], 'crm');

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<CreateNoteInput>({
    defaultValues: {
      comment: '',
      type: 'RISK_MANAGEMENT',
    },
  });

  const handleNoteClick = () => {
    setIsCreateFormOpen(true);
  };

  const createUserNote = (newNote: CreateNoteInput) => {
    const payload = {
      ...newNote,
      userId: selectedCRMUserId,
      author: username,
    };

    createData(
      payload,
      () => {
        invalidateData([`notes/${apiEntity}`]);
        setIsCreateFormOpen(false);
        reset();
      },
      'Note created successfully.'
    );
  };

  return (
    <Box sx={styles.container}>
      {isCreateFormOpen && <TypeSelect control={control} name="type" error={errors.type} options={NOTE_TYPE_OPTIONS} />}
      <TextField
        placeholder="Leave a note"
        {...register('comment')}
        InputProps={{
          startAdornment: !isCreateFormOpen && <EditOutlined sx={styles.icon} />,
          sx: styles.editTextField,
        }}
        onClick={handleNoteClick}
        multiline
      />
      {isCreateFormOpen && (
        <Box sx={styles.btnWrapper}>
          <Button variant="contained" onClick={handleSubmit(createUserNote)} disabled={isLoading || !isDirty}>
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CreateNote;
