import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import {
  CRMUserData,
  CRMApiEntityType,
  Competition,
  EventList,
  PaginatedData,
  Sport,
  Tournament,
  StakeRange,
} from '../@types/api';
import { QUERY_KEYS } from '../constants';
import { getData } from '../utils/api';
import useInfiniteScroll from '../hooks/useInfiniteScroll';

export const useSports = (queryParams?: Record<string, string | number | boolean>) => {
  const params = {
    limit: 25,
    page: 1,
    ...queryParams,
  };

  return useQuery([QUERY_KEYS.sports, params], {
    queryFn: (): Promise<PaginatedData<Sport>> => getData('sports', params),
  });
};

export const useCompetitionsInfiniteScroll = (
  sportIds: string | string[],
  queryParams?: Record<string, string | number | boolean>
) => {
  return useInfiniteScroll<Competition>({
    queryKey: [QUERY_KEYS.competitions],
    endpoint: 'competitions/with-events',
    queryParams: {
      sportIds,
      ...queryParams,
    },
    enabled: !isEmpty(sportIds),
  });
};

export const useTournamentsInfiniteScroll = (
  competitionIds: string | string[],
  queryParams?: Record<string, string | number | boolean>
) => {
  return useInfiniteScroll<Tournament>({
    queryKey: [QUERY_KEYS.tournaments],
    endpoint: 'tournaments/with-events',
    queryParams: {
      competitionIds,
      ...queryParams,
    },
    enabled: !isEmpty(competitionIds),
  });
};

export const useEventsInfiniteScroll = (
  tournamentIds: string | string[],
  queryParams?: Record<string, string | number | boolean | string[] | undefined>
) => {
  return useInfiniteScroll<EventList>({
    queryKey: [QUERY_KEYS.events],
    endpoint: 'events',
    queryParams: {
      tournamentIds,
      ...queryParams,
    },
    enabled: !isEmpty(tournamentIds),
  });
};

export const useCRMUserQuery = (userType: CRMApiEntityType, userId?: string | null, queryParams?: unknown) => {
  const path = userType === 'customer' ? `customer/${userId}` : `agent/by-id/${userId}`;

  return useQuery<CRMUserData>([QUERY_KEYS.crmUsers, userId], {
    queryFn: () => getData(path, queryParams, 'crm'),
    enabled: !!userId,
  });
};

export const useJackpotStakeRanges = () => {
  return useQuery([QUERY_KEYS.stakeRanges], {
    queryFn: (): Promise<PaginatedData<StakeRange>> =>
      getData('jackpots/stake-ranges', {
        page: 1,
        limit: 100,
      }),
  });
};
