import { Stack, Typography } from '@mui/material';

type Outcome = {
  name: string;
  value: string;
};

type Props = {
  outcomes: Outcome[];
};

const LiabilityMonitoringTooltip = ({ outcomes }: Props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">Outcomes</Typography>
      <Stack>
        {outcomes.map((outcome) => (
          <Typography variant="h6" key={outcome.name}>
            {outcome.name}: {outcome.value}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

export default LiabilityMonitoringTooltip;
