import { Box, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { DialogType, StyleObj } from '../../../@types';
import { StyledTab, StyledTabs } from '../../atoms/StyledTabs';
import TabPanel from '../../atoms/TabPanel';
import BetslipControlTab from '../BetslipControlTab';
import SportsLimitsTab from '../SportsLimitsTab';
import { PlayerRisk } from '../../../@types/api';

const styles: StyleObj = {
  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tabPanelWrapper: {
    p: 2,
  },
};

const PLAYER_TAB_CONFIG = [{ label: 'Sports Limits' }, { label: 'Ticket Control' }];

type Props = DialogType & {
  item: PlayerRisk;
};

const RiskManagementPlayerSettingsDialog = ({ closeModal, item }: Props) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, selectedTab: number) => {
    setActiveTab(selectedTab);
  };

  const handleClose = () => {
    closeModal?.();
  };

  return (
    <>
      <DialogTitle variant="h4" gutterBottom>
        Settings
      </DialogTitle>
      <Box sx={styles.tabsWrapper}>
        <StyledTabs value={activeTab} onChange={handleTabChange} variant="fullWidth" indicatorWidth={44}>
          {PLAYER_TAB_CONFIG.map((tab) => (
            <StyledTab label={tab.label} key={tab.label} />
          ))}
        </StyledTabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <Box sx={styles.tabPanelWrapper}>
          <SportsLimitsTab id={item.id} productValue={item.productValue} handleClose={handleClose} />
        </Box>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Box sx={styles.tabPanelWrapper}>
          <BetslipControlTab
            id={item.id}
            productValue={item.productValue}
            handleClose={handleClose}
            rejectAll={item?.rejectAll || undefined}
            verifyAll={item?.verifyAll || undefined}
            timeDelaySeconds={item?.timeDelaySeconds || undefined}
          />
        </Box>
      </TabPanel>
    </>
  );
};

export default RiskManagementPlayerSettingsDialog;
