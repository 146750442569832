import { Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { DialogType } from '../../../@types';
import { LS_KEYS } from '../../../constants';
import { JACKPOT_PRODUCT_OPTIONS } from '../../../constants/jackpot';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import { useJackpotStakeRanges } from '../../../queries';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import FormNumberInput from '../../molecules/FormNumberInput';
import FormSelect from '../../molecules/FormSelect';
import StakeRangeSelect from '../../molecules/StakeRangeSelect';

const DEFAULT_FORM_DATA = {
  product: undefined,
  stakeRangeId: undefined,
  winningRange: {
    from: undefined,
    to: undefined,
  },
  isActive: undefined,
};

type JackpotFilterData = {
  product?: string;
  stakeRangeId?: string;
  winningRange?: {
    from?: number;
    to?: number;
  };
  isActive: boolean;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const FilterJackpots = ({ closeModal, changeQuery }: Props) => {
  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);
  const { pathname } = useLocation();
  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;

  const getSavedData = () => getPersistData<JackpotFilterData>(formDataKey) || DEFAULT_FORM_DATA;

  const { data: stakeRangesData } = useJackpotStakeRanges();

  const { control, handleSubmit } = useForm<JackpotFilterData>({
    defaultValues: getSavedData(),
  });

  const onFormSubmit = (data: JackpotFilterData) => {
    setPersistData<JackpotFilterData>(formDataKey, data);
    changeQuery(data);
    closeModal?.();
  };

  const handleResetFilter = () => {
    deletePersistData(formDataKey);
    changeQuery(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  return (
    <FilterDialogLayout
      label="jackpots"
      onClose={() => {
        closeModal?.();
      }}
      onSave={handleSubmit(onFormSubmit)}
      onReset={handleResetFilter}
    >
      <FormFieldStack label="Status">
        <Controller name="isActive" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
      <FormSelect control={control} name="product" label="Product" options={JACKPOT_PRODUCT_OPTIONS} />
      <Typography variant="h6" fontWeight={600}>
        Winning amount range
      </Typography>
      <Stack direction="row" spacing={2}>
        <FormNumberInput name="winningRange.from" control={control} label="From" fullWidth />
        <FormNumberInput name="winningRange.to" control={control} label="To" fullWidth />
      </Stack>
      <StakeRangeSelect
        name="stakeRangeId"
        control={control}
        label="Ticket pay in amount"
        options={stakeRangesData?.items || []}
      />
    </FilterDialogLayout>
  );
};

export default FilterJackpots;
