import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogType } from '../../../@types';
import { QUERY_KEYS } from '../../../constants';
import useMutateData from '../../../hooks/useMutateData';
import { EventFormData, createEventFormSchema } from '../../../schema';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import DateTimeSelect from '../../molecules/DateTimeSelect';
import ParticipantSelect from '../../molecules/ParticipantSelect';
import SportSelect from '../../molecules/SportSelect';
import TournamentSelect from '../../molecules/TournamentSelect';
import FormAutocomplete from '../FormAutocomplete';
import { isEmpty } from 'lodash-es';

const DEFAULT_EVENT_FORM_DATA = {
  sportId: undefined,
  competitionId: undefined,
  tournamentId: undefined,
  participantIds: [],
  startDate: dayjs().add(1, 'hour'),
  information: null,
  featured: false,
};

const CreateEventForm = ({ closeModal }: DialogType) => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<EventFormData>({
    defaultValues: DEFAULT_EVENT_FORM_DATA,
    resolver: zodResolver(createEventFormSchema),
  });

  const { createData } = useMutateData('events', [QUERY_KEYS.events]);

  const handleClose = () => {
    reset(DEFAULT_EVENT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (newEvent: EventFormData) => {
    const payload = {
      tournamentId: newEvent.tournamentId,
      participantIds: newEvent.participantIds,
      startDate: newEvent.startDate.set('second', 0).toISOString(),
      information: newEvent.information,
      featured: newEvent.featured,
    };

    createData(payload, () => {
      handleClose();
    });
  };

  const sportId = watch('sportId');
  const competitionId = watch('competitionId');
  const tournamentId = watch('tournamentId');

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId') {
        setValue('competitionId', '');
        setValue('tournamentId', '');
        setValue('participantIds', []);
      }

      if (name === 'competitionId') {
        setValue('tournamentId', '');
        setValue('participantIds', []);
      }

      if (name === 'tournamentId') {
        setValue('participantIds', []);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <FormModalLayout onSave={handleSubmit(onFormSubmit)} label="Add new event" onClose={handleClose} isEdit={false}>
      <SportSelect
        control={control}
        name="sportId"
        label="Sport"
        required
        closeMenuOnSelect
        error={errors.sportId}
        queryParams={{ isActive: true }}
      />
      <FormAutocomplete
        name="competitionId"
        control={control}
        label="Competitions"
        required
        url="competitions"
        queryKey={[QUERY_KEYS.competitions, sportId]}
        queryParams={{
          sportId: sportId,
          isActive: true,
        }}
        disabled={isEmpty(sportId)}
        hookEnabled={!!sportId}
        error={errors.competitionId}
        getOptionLabel={(options, value) => {
          const option = options.find((option) => option?.id === value);
          return option?.name || '';
        }}
      />
      <TournamentSelect
        name="tournamentId"
        control={control}
        label="Tournament"
        required
        competitionIds={competitionId && [competitionId]}
        error={errors.tournamentId}
        closeMenuOnSelect
      />
      <DateTimeSelect control={control} name="startDate" error={errors.startDate} disablePast />
      <ParticipantSelect
        control={control}
        required
        name="participantIds.0"
        label="Participant 1"
        queryParams={{ tournamentId }}
        error={errors.participantIds?.[0]}
      />
      <ParticipantSelect
        control={control}
        name="participantIds.1"
        label="Participant 2"
        required
        queryParams={{ tournamentId }}
        error={errors.participantIds?.[1]}
      />
      <TextField
        error={!!errors.information}
        helperText={errors.information?.message}
        label="Information"
        rows={3}
        multiline
        {...register('information')}
      />
      <FormFieldStack label="Featured">
        <Controller name="featured" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
    </FormModalLayout>
  );
};

export default CreateEventForm;
