import { ChevronRight } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Stack, Table, Theme, Tooltip, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { StyleObj } from '../../@types';
import { BetStatus, Event, Tournament } from '../../@types/api';
import { QUERY_KEYS, RESOLVE_STATUS_COLORS } from '../../constants';
import { getLatestValue, getNameToDisplay } from '../../helpers';
import useContextMenu from '../../hooks/useContextMenu';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';
import useMarketsInfiniteScroll from '../../hooks/useMarketsInfiniteScroll';
import useMutateData from '../../hooks/useMutateData';
import { getData } from '../../utils/api';
import ResolveOutcomeMenu, { HandleMenuItemClickOption } from '../molecules/ResolveOutcomeMenu';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: '1px solid rgba(0, 83, 55, 0.20)',
    background: theme.palette.background.lightGreen,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '120%',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: '1px solid',
    borderTop: 0,
    borderColor: theme.palette.primary.container,
    padding: 0,
    '&:first-of-type': {
      padding: '0 2px',
    },
  },
}));

const StyledOutcomeBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'resolveStatus' && prop !== 'isSelected',
})<{ resolveStatus: BetStatus; isSelected?: boolean } & SystemStyleObject<Theme>>(({
  theme,
  resolveStatus,
  isSelected,
}) => {
  const outcomeColor = RESOLVE_STATUS_COLORS[resolveStatus];
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid',
    borderColor: theme.palette.primary.container,
    width: 100,
    minHeight: 40,
    cursor: 'context-menu',
    px: 1,
    backgroundColor: typeof outcomeColor === 'string' ? outcomeColor : 'transparent',
    backgroundImage: Array.isArray(outcomeColor)
      ? `linear-gradient(90deg, ${outcomeColor[0]} 50%, ${outcomeColor[1]} 50%)`
      : 'none',
    filter: isSelected ? 'brightness(0.8)' : 'none',
  };
});

const styles: StyleObj = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    mt: 11,
    width: '50%',
  },
  eventNameWrapper: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.light,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    px: 1.5,
    py: 1.25,
  }),
  eventInfoWrapper: (theme) => ({
    backgroundColor: theme.palette.background.lighter,
    px: 1.5,
    py: 1.25,
  }),
  chevronIconBtn: {
    p: 0,
    pr: 0.5,
  },
  tableContainer: {
    borderRadius: 0,
  },
  tableHead: { height: 42 },
  marketNameHeadCell: {
    minWidth: 150,
  },
  outcomeCell: {
    display: 'flex',
    p: 0,
  },
  resolveStatusChangeIndicator: {
    cursor: 'help',
  },
};

type ResolveTableProps = {
  eventId: string;
  tournament?: Tournament;
  handleClose: () => void;
};

const ResolveTable = ({ eventId, tournament, handleClose }: ResolveTableProps) => {
  const [selectedOutcomeId, setSelectedOutcomeId] = useState<string | null>(null);

  const queryKey = [QUERY_KEYS.results, { id: eventId }];

  const invalidateData = useInvalidateQuery();
  const { contextMenu, showContextMenu, hideContextMenu } = useContextMenu();

  const { data: event } = useQuery({
    queryKey: queryKey,
    queryFn: (): Promise<{ item: Event }> =>
      getData(`events/${eventId}`, {
        showDisabledOutcomes: true,
      }),
    select: (data) => data.item,
    cacheTime: 0,
  });

  const { markets, isFetchingNextPage, lastElementRef } = useMarketsInfiniteScroll(eventId);

  const { createData: createOutcomeChangesData } = useMutateData('outcome-changes', [
    QUERY_KEYS.markets,
    {
      eventId,
    },
  ]);

  const updateResolveStatus = (option: HandleMenuItemClickOption) => {
    createOutcomeChangesData(
      {
        outcomeId: selectedOutcomeId,
        resolveStatus: option.id,
      },
      () => {
        invalidateData([QUERY_KEYS.results]);
        hideContextMenu();
      },
      'Item updated successfully.'
    );
  };

  const handleShowContextMenu = (e: React.MouseEvent, outcomeId?: string) => {
    if (!outcomeId) return;

    setSelectedOutcomeId(outcomeId);
    showContextMenu(e);
  };

  const startDate = event && getLatestValue(event, 'startDate');

  const maxNumberOfOutcomes =
    markets?.reduce((acc, market) => {
      return market.outcomes.length > acc ? market.outcomes.length : acc;
    }, 0) || 0;

  const renderEmptyCells = (numberOfOutcomes: number) => {
    const numberOfEmptyCells = maxNumberOfOutcomes - numberOfOutcomes;
    const emptyCells = [];
    for (let i = 0; i < numberOfEmptyCells; i++) {
      emptyCells.push(
        <Box
          key={i}
          sx={{
            ...styles.outcomeBox,
            backgroundColor: 'transparent',
          }}
        />
      );
    }
    return emptyCells;
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.eventNameWrapper}>
        <IconButton onClick={handleClose} sx={styles.chevronIconBtn} color="inherit">
          <ChevronRight />
        </IconButton>
        {event && (
          <Typography variant="h6" fontWeight={700}>
            {getLatestValue(event, 'name')}|{dayjs(startDate).format('DD.MM.YYYY')}|{dayjs(startDate).format('HH:mm')}
          </Typography>
        )}
      </Box>
      <Box sx={styles.eventInfoWrapper}>
        <Typography variant="h6" fontWeight={600}>
          {tournament?.sport.name}|{tournament?.competition.name}|{tournament?.name}
        </Typography>
      </Box>
      <TableContainer sx={styles.tableContainer}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <StyledTableCell component="th" align="center" sx={styles.marketNameHeadCell}>
                Market Name
              </StyledTableCell>
              <StyledTableCell component="th" align="center">
                SV
              </StyledTableCell>
              <StyledTableCell component="th" align="center">
                Outcomes
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {markets?.map((market) => {
              return (
                <TableRow key={market.id} ref={lastElementRef}>
                  <StyledTableCell align="center">{getNameToDisplay(market)}</StyledTableCell>
                  <StyledTableCell align="center">
                    {isEmpty(market.specialValues)
                      ? '-'
                      : market.specialValues?.map((sv) => {
                          return (
                            <Typography key={sv.id} variant="body2">
                              {getLatestValue(sv, 'value')}
                            </Typography>
                          );
                        })}
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={styles.outcomeCell}>
                    {market.outcomes.map((outcome) => {
                      const latestResolveStatus = outcome.latestTransferStatusChanges?.resolveStatus;
                      return (
                        <StyledOutcomeBox
                          key={outcome.id}
                          onContextMenu={(e) => {
                            handleShowContextMenu(e, outcome.id);
                          }}
                          resolveStatus={latestResolveStatus ?? outcome.resolveStatus}
                          isSelected={selectedOutcomeId === outcome.id && !!contextMenu}
                        >
                          <Typography variant="body2" color="white">
                            {getNameToDisplay(outcome)}
                            {latestResolveStatus && (
                              <Tooltip title={`Previous status: ${outcome.resolveStatus}`} placement="top">
                                <Box component={'span'} sx={styles.resolveStatusChangeIndicator}>
                                  *
                                </Box>
                              </Tooltip>
                            )}
                          </Typography>
                        </StyledOutcomeBox>
                      );
                    })}
                    {renderEmptyCells(market.outcomes.length)}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {isFetchingNextPage && (
          <Stack justifyContent="center" alignItems="center" p={1}>
            <CircularProgress />
          </Stack>
        )}
      </TableContainer>
      <ResolveOutcomeMenu
        handleClose={hideContextMenu}
        contextMenu={contextMenu}
        handleItemClick={(option) => updateResolveStatus(option)}
      />
    </Box>
  );
};

export default ResolveTable;
