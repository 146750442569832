import { isArray, isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { DialogType } from '../../../@types';
import {
  Competition,
  ConditionEntityType,
  MarketType,
  MarketTypeType,
  MarketTypeUsedForEnum,
} from '../../../@types/api';
import {
  CONDITION_ENTITY_SELECT_OPTIONS,
  CONDITION_NAME_SELECT_OPTIONS,
  CONDITION_TYPE_ENTITY_SELECT_OPTIONS,
  LANGUAGE_SELECT_VALUES,
  LS_KEYS,
  MARKET_TYPE_TYPE_OPTIONS,
  MARKET_TYPE_USED_FOR_OPTIONS,
  MODEL_SELECT_FORM_VALUES,
  QUERY_KEYS,
} from '../../../constants';
import { AdditionalQueryParams } from '../../../hooks/usePagination';
import usePersist from '../../../hooks/usePersist';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FilterDialogLayout from '../../layouts/FilterDialogLayout';
import FormSelect from '../../molecules/FormSelect';
import LiabilityLimitSelect from '../../molecules/LiabilityLimitSelect';
import ParticipantSelect from '../../molecules/ParticipantSelect';
import RiskFactorSelect from '../../molecules/RiskFactorSelect';
import SportSelect from '../../molecules/SportSelect';
import TournamentSelect from '../../molecules/TournamentSelect';
import { URL_TO_CONDITION_MODEL_TYPE_MAP } from '../../pages/conditions/ConditionEntityPage';
import FormAutocomplete from '../FormAutocomplete';

export const defaultValues = {
  isActive: true,
  language: undefined, // this should be set to en by default
  sportIds: [],
  competitionIds: [],
  tournamentIds: [],
  participantIds: [],
  marketTypeIds: [],
  updateType: [],
  usedFor: [],
  type: undefined,
  name: undefined,
  sportId: undefined,
  riskFactorId: undefined,
  liabilityLimitId: undefined,
  marketTypeType: undefined,
};

export type FilterPreMatchSettingsData = {
  isActive: boolean;
  language: string;
  sportIds: string[];
  competitionIds: string[];
  tournamentIds: string[];
  participantIds: string[];
  marketTypeIds: string[];
  updateType: string[];
  type: ConditionEntityType; // Condition type
  name: string; // Condition name
  sportId: string;
  usedFor: MarketTypeUsedForEnum[];
  riskFactorId: string;
  liabilityLimitId: string;
  marketTypeType: MarketTypeType;
};

type Props = DialogType & {
  changeQuery: (data: AdditionalQueryParams) => void;
};

const labels = {
  sports: 'sports',
  competitions: 'competitions',
  tournaments: 'tournaments',
  participants: 'participants',
  players: 'players',
  markets: 'markets',
  outcomes: 'outcomes',
  results: 'results',
  'conditions-type': 'condition types',
  'conditions-groups': 'condition groups',
  marketGroups: 'market groups',
  'risk-management': 'risk management',
};

const getValuesToReset = (section: string, resource: string) => {
  const defaultResetValues = {
    ...defaultValues,
    isActive: undefined,
    language: undefined,
  };

  const conditionType = URL_TO_CONDITION_MODEL_TYPE_MAP[resource];

  const resourceMap: Record<string, Record<string, Partial<FilterPreMatchSettingsData>>> = {
    'market-groups': {
      settings: {
        sportId: undefined,
        isActive: true,
        usedFor: [],
      },
    },
    [resource]: {
      conditions: {
        ...defaultValues,
        type: conditionType,
      },
    },
  };

  return resourceMap[resource]?.[section] || defaultResetValues;
};

const FilterPreMatchSettingsForm = ({ changeQuery, closeModal }: Props) => {
  /*
    Using location state to pass the selected market type from the markets to the outcomes table
    so we can pre-populate the Market select component in the Add Outcome form modal and
    filter the table with the appropriate filters
  */
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const pathSegments = pathname.split('/');
  const resource = pathSegments.pop() as keyof typeof labels;

  const parentResourceType = pathSegments[pathSegments.length - 1];

  const { setPersistData, getPersistData, deletePersistData } = usePersist(false);

  const formDataKey = `${LS_KEYS.appFormFilter}-${pathname}`;
  const getSavedData = () => getPersistData<FilterPreMatchSettingsData>(formDataKey);

  const { control, handleSubmit, watch, reset, setValue } = useForm<FilterPreMatchSettingsData>({
    defaultValues: getSavedData() || state?.defaultFilters || defaultValues,
  });

  const sportIds = watch('sportIds');
  const competitionIds = watch('competitionIds');
  const tournamentIds = watch('tournamentIds');
  const marketIds = watch('marketTypeIds');
  const participantIds = watch('participantIds');

  useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportIds') {
        if (!isEmpty(competitionIds)) {
          setValue('competitionIds', []);
        }
        if (!isEmpty(tournamentIds)) {
          setValue('tournamentIds', []);
        }
        if (!isEmpty(marketIds)) {
          setValue('marketTypeIds', []);
        }
      }
      if (name === 'competitionIds') {
        if (!isEmpty(tournamentIds)) {
          setValue('tournamentIds', []);
        }
      }
      if (name === 'tournamentIds') {
        if (!isEmpty(competitionIds)) {
          setValue('participantIds', []);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, competitionIds, tournamentIds, marketIds]);

  useEffect(() => {
    if (parentResourceType === 'conditions' && URL_TO_CONDITION_MODEL_TYPE_MAP[resource]) {
      setValue('type', URL_TO_CONDITION_MODEL_TYPE_MAP[resource]);
    }
  }, [setValue, resource, parentResourceType]);

  const handleClose = () => {
    closeModal?.();
  };

  const handleFilterReset = () => {
    deletePersistData(formDataKey);
    reset(defaultValues);

    // needs to be further improved, it should be dynamic based on the resource
    const resetValues = getValuesToReset(parentResourceType, resource);
    changeQuery(resetValues);

    // Reset location state to prevent market type being populated in the Add form after resetting filters
    navigate(pathname, { state: undefined });
    closeModal?.();
  };

  useEffect(() => {
    if (state?.defaultFilters) {
      changeQuery({
        ...state?.defaultFilters,
        isActive: undefined,
        language: undefined,
      });
    }
  }, [changeQuery, state?.defaultFilters]);

  const onFormSubmit = (data: FilterPreMatchSettingsData) => {
    setPersistData<FilterPreMatchSettingsData>(formDataKey, data);
    changeQuery(data);
    handleClose();
  };

  const show = (section: string) => {
    const resourceMap: Record<string, Record<string, string[]>> = {
      sports: {
        settings: ['language', 'status'],
        conditions: ['status'],
        'risk-management': ['riskFactor', 'liabilityLimit'],
      },
      competitions: {
        settings: ['language', 'status', 'sports'],
        conditions: ['status', 'sports'],
      },
      tournaments: {
        settings: ['language', 'status', 'sports', 'competition'],
        conditions: ['status', 'sports', 'competition'],
        'risk-management': ['sports', 'competition', 'riskFactor', 'liabilityLimit'],
      },
      participants: {
        settings: ['language', 'status', 'sports', 'competition', 'tournament'],
      },
      players: {
        settings: ['language', 'status', 'sports', 'competition', 'tournament', 'participant'],
        'risk-management': ['riskFactor', 'liabilityLimit'],
      },
      markets: {
        settings: ['language', 'status', 'sports', 'marketTypeType'],
        conditions: ['status', 'sports', 'marketType'],
        'risk-management': ['sports', 'riskFactor', 'liabilityLimit'],
      },
      outcomes: {
        settings: ['language', 'status', 'sports', 'marketType'],
      },
      results: {
        settings: ['sports', 'competition', 'selectType'],
      },
      'market-groups': {
        settings: ['sportId', 'status', 'usedFor'],
      },
      'conditions-type': {
        conditions: ['conditionName', 'entity'],
      },
      'conditions-groups': {
        conditions: ['entity'],
      },
      punters: {
        conditions: ['status'],
      },
    };

    const resourceFilters = resourceMap[resource]?.[parentResourceType] || [];

    return resourceFilters.includes(section);
  };

  return (
    <FilterDialogLayout
      onSave={handleSubmit(onFormSubmit)}
      label={labels[resource]}
      onClose={handleClose}
      onReset={handleFilterReset}
    >
      {show('selectType') && (
        <FormSelect label="Model Type" name="updateType" control={control} options={MODEL_SELECT_FORM_VALUES} />
      )}
      {show('status') && (
        <FormFieldStack label="Status">
          <Controller name="isActive" control={control} render={({ field }) => <Switch {...field} ref={null} />} />
        </FormFieldStack>
      )}
      {show('language') && (
        <FormSelect label="Language" name="language" control={control} options={LANGUAGE_SELECT_VALUES} />
      )}
      {show('sports') && <SportSelect control={control} name="sportIds" multiple closeMenuOnSelect />}
      {show('sport') && <SportSelect control={control} name="sportIds" closeMenuOnSelect />}
      {show('sportId') && <SportSelect control={control} name="sportId" closeMenuOnSelect />}
      {show('competition') && (
        <FormAutocomplete<Competition, FilterPreMatchSettingsData>
          name="competitionIds"
          control={control}
          label="Competitions"
          multiple
          url="competitions"
          queryKey={[QUERY_KEYS.competitions, sportIds, competitionIds]}
          queryParams={{
            sportIds,
            isActive: true,
            competitionIds,
          }}
          disabled={isEmpty(sportIds)}
          hookEnabled={!!sportIds}
          getOptionLabel={(options, value) => {
            const option = options.find((option) => option?.id === value);
            return option ? option.name : '';
          }}
        />
      )}
      {show('tournament') && (
        <TournamentSelect
          control={control}
          name="tournamentIds"
          competitionIds={competitionIds}
          multiple
          closeMenuOnSelect
        />
      )}
      {show('participant') && (
        <ParticipantSelect
          control={control}
          name="participantIds"
          queryParams={{ tournamentIds, participantIds: isArray(participantIds) ? participantIds : [participantIds] }}
          multiple
        />
      )}
      {show('marketType') && (
        <FormAutocomplete<MarketType, FilterPreMatchSettingsData>
          name="marketTypeIds"
          control={control}
          label="Markets"
          multiple
          url="market-types"
          queryKey={[QUERY_KEYS.marketTypes, sportIds, marketIds]}
          queryParams={{
            sportIds: isArray(sportIds) ? sportIds : [sportIds],
            isActive: true,
            marketTypeIds: isArray(marketIds) ? marketIds : [marketIds],
          }}
          disabled={isEmpty(sportIds)}
          hookEnabled={!!sportIds}
          getOptionLabel={(options, value) => {
            const option = options.find((option) => option?.id === value);
            return option ? `${option?.name}, ${option?.eventPartName || ''}` : '';
          }}
        />
      )}
      {show('entity') && (
        <FormSelect
          label="Entity"
          name="type"
          control={control}
          options={
            resource === 'conditions-type' ? CONDITION_TYPE_ENTITY_SELECT_OPTIONS : CONDITION_ENTITY_SELECT_OPTIONS
          }
        />
      )}
      {show('conditionName') && (
        <FormSelect label="Name" name="name" control={control} options={CONDITION_NAME_SELECT_OPTIONS} />
      )}
      {show('usedFor') && (
        <FormSelect
          label="Used for"
          name="usedFor"
          control={control}
          multiple
          options={MARKET_TYPE_USED_FOR_OPTIONS}
          closeOnSelect
        />
      )}
      {show('riskFactor') && <RiskFactorSelect name="riskFactorId" control={control} closeMenuOnSelect />}
      {show('liabilityLimit') && <LiabilityLimitSelect name="liabilityLimitId" control={control} closeMenuOnSelect />}
      {show('marketTypeType') && (
        <FormSelect
          label="Market type"
          name="type"
          options={MARKET_TYPE_TYPE_OPTIONS.slice(0, -1)}
          control={control}
          closeOnSelect
        />
      )}
    </FilterDialogLayout>
  );
};

export default FilterPreMatchSettingsForm;
