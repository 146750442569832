import { SVGProps } from 'react';
import { PublishStatus } from '../../@types/api';
import { PUBLISH_STATUS_ICON_COLORS } from '../../constants';

type PublishStatusIconProps = SVGProps<SVGSVGElement> & {
  changed?: boolean;
  status: PublishStatus;
};

const PublishStatusIcon = (props: PublishStatusIconProps) => {
  const { changed, status } = props;

  const fill = PUBLISH_STATUS_ICON_COLORS[status];
  return (
    <svg width="24" height="24" fill="none">
      <path fill={fill} fillRule="evenodd" d="M12 19a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z" clipRule="evenodd" />
      <path
        fill={changed ? '#B3261E' : fill}
        fillRule="evenodd"
        d="M12 17.333a5.333 5.333 0 1 0 0-10.666 5.333 5.333 0 0 0 0 10.666ZM20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default PublishStatusIcon;
