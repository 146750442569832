import { Tab, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { MarketGroupsList, PaginatedData } from '../../@types/api';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';
import { getNameToDisplay } from '../../helpers';

const makeTabStyle = (active: boolean, numberOfTabs: number) => {
  return {
    tab: {
      minWidth: 0,
      textTransform: 'none',
      minHeight: '50px',
      fontWeight: 600,
      borderBottom: active ? '2px solid' : '2px solid transparent',
      transition: 'all 0.2s ease-in-out',
      '&.Mui-selected': {
        borderBottomColor: 'primary.main',
      },
    },
  };
};

type Props = {
  sportId: string;
  activeTab: string | false;
  setActiveTab: (tab: string) => void;
};

const EventTabs = ({ sportId, activeTab, setActiveTab }: Props) => {
  const { data: marketsData } = useQuery([QUERY_KEYS.marketGroups, sportId], {
    queryFn: (): Promise<PaginatedData<MarketGroupsList>> =>
      getData('market-type-groups', {
        sportId: sportId,
        displayType: 'match',
        isActive: true,
        limit: 100,
      }),
    enabled: !!sportId,
    select: (response) => response.items,
  });

  const tabs = useMemo(
    () =>
      marketsData?.map((market) => {
        return {
          label: getNameToDisplay(market),
          value: market.id,
        };
      }),
    [marketsData]
  );

  const handleTabChange = (_: React.SyntheticEvent, tabValue: string) => {
    setActiveTab(tabValue);
  };

  return (
    <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons>
      <Tab
        value={false}
        sx={makeTabStyle(activeTab === false, (tabs?.length || 0) + 1).tab}
        label="All"
        disableRipple
      />
      {tabs?.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          sx={makeTabStyle(activeTab === tab.value, tabs.length + 1).tab}
          label={tab.label}
          disableRipple
        />
      ))}
    </Tabs>
  );
};

export default EventTabs;
